@import "font";
@import "color";
@import "points";

// $btn-font-family: 
.lmsbtn {
  display: inline-block;
  // font-family: $lms_SemiBold;
  color: $lms_Greyscale_White;
  text-align: center;
  // white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: $lms_Primary_Blue;
  min-width: 80px;
  height: 40px;
  font-weight: 400;
  font-size: 20px;
  margin: 82px 0px 20px 0px;

  /* or 160% */


  &:hover {
    background-color: $lms_Greyscale_White;
    border: 1px solid $lms_Primary_Blue;
    color: $lms_Greyscale_Black;

  }
}

.sessionbtn {
  display: inline-block;
  font-family: $lms_SemiBold;
  color: $lms_Greyscale_White;
  text-align: center;
  // white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: $lms_Primary_Blue;
  min-width: 80px;
  height: 40px;
  font-weight: 500;
  font-size: 20px;
  margin: 30px 0px 20px 0px;

  /* or 160% */


  &:hover {
    background-color: $lms_Greyscale_White;
    border: 1px solid $lms_Primary_Blue;
    color: $lms_Greyscale_Black;

  }
}


// $btn-font-family: 
.backbtn {
  display: inline-block;
  font-family: "Signika-SemiBold";
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #003366;
  margin: 0 5px !important;
  height: 40px;
  width: 40px;
  font-size: 22px;

  &:hover {
    background-color: $lms_Greyscale_White;
    border: 1px solid $lms_Primary_Blue;
    color: $lms_Greyscale_Black;

  }
}

.backbtnunit {
  display: inline-block;
  font-family: "Signika-SemiBold";
  color: #003366;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  // background-color: #003366;
  margin: 0 5px !important;
  height: 40px;
  width: 40px;
  font-size: 22px;
}

.lmsbtnTwo {
  display: inline-block;
  font-family: $lms_SemiBold;
  color: $lms_Greyscale_White;
  text-align: center;
  // white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  // background-color: $lms_Primary_Blue;
  background: $lms_Accent_Green;
  min-width: 80px;
  height: 40px;
  font-weight: 700;
  font-size: 20px;
  margin: 30px 0px 20px 0px;

  /* or 160% */


  &:hover {
    background-color: $lms_Greyscale_White;
    border: 1px solid $lms_Primary_Blue;
    color: $lms_Greyscale_Black;

  }
}

.lmscanclebtn {
  display: inline-block;
  font-family: $lms_SemiBold;
  color: $lms_Greyscale_Black;
  text-align: center;
  // white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: $lms_Greyscale_White;
  min-width: 80px;
  height: 40px;
  font-weight: 700;
  font-size: 20px;
  border: 1px solid $lms_Primary_Blue;
  margin: 30px 10px 20px 10px;

  /* or 160% */


  &:hover {
    background-color: $lms_Greyscale_White;
    border: 1px solid $lms_Primary_Blue;
    color: $lms_Greyscale_Black;

  }
}

.tablebtn {
  display: inline-block;
  font-family: "Signika-SemiBold";
  color: $lms_Greyscale_White;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: $lms_Primary_Blue;
  min-width: 100px;
  height: 18px;
  font-weight: 700;
  font-size: 14px;
  margin: 8px;
  height: 27px;
  display: flex;
  justify-content: center;
  padding: 3px;

  &:hover {
    background-color: $lms_Greyscale_White;
    border: 1px solid $lms_Primary_Blue;
    color: $lms_Greyscale_Black;

  }

  /* margin: auto; */
}

.Activatebtn {
  display: inline-block;
  font-family: "";
  color: $lms_Greyscale_White;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: $lms_Accent_Green;
  min-width: 90%;
  height: 18px;
  font-weight: 700;
  font-size: 14px;
  margin: 8px;
  height: 27px;
  display: flex;
  justify-content: center;
  padding: 5px;

  i {
    font-weight: 900;
  }
}

.InActivatebtn {
  display: inline-block;
  font-family: "Signika-SemiBold";
  color: $lms_Greyscale_Black;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: darken($lms_Primary_Red, .5%);
  opacity: .7;
  min-width: 90%;
  height: 18px;
  font-weight: 700;
  font-size: 14px;
  margin: 8px;
  height: 27px;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.Activatetext {

  display: inline-block;
  font-family: "Signika-SemiBold";
  color: $lms_Greyscale_Black;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: darken($lms_Primary_Red, .5%);
  opacity: .7;
  min-width: 90%;
  height: 18px;
  font-weight: 700;
  font-size: 14px;
  margin: 8px;
  height: 27px;
  display: flex;
  justify-content: center;
  padding: 5px;
}

// .bread-crumbs-back-btn {
//   display: inline-block;
//   font-family: "Signika-SemiBold";
//   color: #003366;
//   text-align: center;
//   vertical-align: middle;
//   -webkit-user-select: none;
//   user-select: none;
//   // background-color: #003366;
//   margin: 0 0px !important;
//   height: 40px;
//   width: 40px;
//   font-size: 22px;
// }

.btnview {

  color: $lms_Accent_Green;
}

.btnedit {

  color: $lms_Accent_Green;

}

.btntrash {
  color: $lms_Primary_Red;
}

.InActivatetext {}

// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {}

// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xs() {}

// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {}

// Tablets and small desktops /*media screen for maximum width 992px*/
@include screen-md() {}

// Large tablets and desktops /*media screen for maximum width 1024*/
@include screen-lg() {}

// Large tablets and desktops /*media screen for maximum width 1400*/
@include screen-xl() {}

@include screen-xxl() {}