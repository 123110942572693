@import '../../../template/all.scss';

.reattempt_warning {
    padding: 7px 15px;
    background: whitesmoke;
    font-size: 13px;
    border-radius: 6px;
    margin: 0 15px 15px 15px;
}
.reattempt_warning h6 {
    margin: 0;
    font-size: 14px;
    margin-bottom: 3px;
}
.reattempt_warning p {
    margin: 0;
    text-transform: math-auto;
}
span.reattempt_warning_icon {
    margin-right: 5px;
    background: #0D4C86;
    color: yellow;
    height: 20px;
    width: 20px;
    display: inline-block;
    text-align: center;
    line-height: 18px;
    border-radius: 50px;
}
span.reattempt_warning_icon i {
    font-size: 12px;
}
// .react-pdf__Document {
//     // background-color: #E84C3D;
// }

// .react-pdf__Document.stf__parent {
//     width: 350px;
//     height: 500px;
// }

// .react-pdf__Page__textContent {
//     width: 350px !important;
//     height: 500px !important;
// }
// // .stf__block {
// //     position: absolute;
// //     width: 350px !important;
// //     height: 500px !important;
// //     box-sizing: border-box;
// //     perspective: 2000px;
// // }
// .react-pdf__Page__canvas{
//     width: 350px !important;
//     height: 500px !important;
// }
// .stf__item {
//     width: 350px !important;
//     height: 500px !important;
// }

// .react-pdf__Page__canvas{
//     width: 350px !important;
//     height: 500px !important;
// }



.post-module .exam_thumbnail img{
    height: 217px;
    width: 100%;
    overflow: hidden;
    background: #162f6624;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.icon_css {
    margin-right: 10px;
}

.lmsbtn-exam {
    display: inline-block;
    // color: $lms_Greyscale_White;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    // background-color: $lms_Primary_Blue;
    min-width: 80px;
    height: 40px;
    font-weight: 400;
    font-size: 20px;
    margin: 82px 0px 20px 0px;
  
    /* or 160% */
  
  
    &:hover {
    //   background-color: $lms_Greyscale_White;
    //   border: 1px solid $lms_Primary_Blue;
    //   color: $lms_Greyscale_Black;
  
    }
  }

  .post-module-exam {
    position: relative;
    z-index: 1;
    display: block;
    background: red;
    /* min-width: 270px; */
    height: 388px;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    // border: 1px solid $lms_Primary_Blue;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.title-head-exam {
    top:70px;
    margin: 0;
    padding: 0 0 10px;
    text:  black;
    font-size: 20px;
    font-weight: 600;
}

// .exam_banner img {
//     width: 100%;
//     height: 200px;
// }

.exam_banner img {
    width: 100%;
    height: 200px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modal-body {
    padding: 0;
}

.container-fluid {
    padding: 0;
    border-radius: 10px;
}


// .modal-footer button {
//     justify-content: flex-start;
//     background-color: #0A233E;
//     border: none;
//     padding-left: 25px;
//     padding-right: 25px;
// }

.quiz {
    /* height: 388px; */
    border-radius: 15px;
    border: solid 1px;
    background-color: white;
    align-items: center;
}

.quiz-image-container img{
    width: 100%;
    border-radius: 15px;
    // Uncomment
    // height: 217px;
    height: 175px;
}

.quiz-details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
}

.quiz-status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 10px;
    margin: 10px 0px 10px 39px;
}

.quiz-status div {
    padding-right: 30px;
}

.exam-title {
    padding: 10px 0px 10px 0px;
    font-size: 18px;
    margin: 10px 0px 0px 39px;
    font-weight: bold;
    // Uncomment
    // height: 75px;
}
.examname-title {
    padding: 10px 0px 10px 0px;
    font-size: 18px;
    margin: 10px 0px 0px 39px;
    // Uncomment
    // height: 75px;
}
.quiz{
    height: 100%;
}

// .take-exam {
//     width: 160px;
//     align-self: center;
//     margin: 0px 0px 20px 0px;
//     padding: 5px 0px 5px 0px;
//     border-radius: 10px;
//     border: none;
//     color: white;
//     font-size: 14px;
//     background-color: #0A233E;
// }

// .take-exam:hover {
//     background-color: white;
//     border: 1px solid #0A233E;
//     color: gray;
//   }

.Questionhead {
    background: #E84C3D;
    margin-left: -12px;
    border-radius: 10px;
    /* color: $lms_Greyscale_White;
    
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 10px;

    &::before {
        background: url("../../../template/img/QA.png");
    } */
}

.Questionrow {
    display: flex;
    justify-content: space-between;
    /* margin-left: 24px; */
    border-bottom: 2px solid #E84C3D;
    
}

.primary-bg-blue-color{
    background-color: #0A233E!important;
    color: red;
  }

.orange-bg-color {
    background-color: #E84C3D !important;
}

.orange-bb-color {
    border-bottom-color: #E84C3D !important;
}

.modalpagetitle {
    // font-family: $lms_Bold;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    width: 100%;
    padding: 17px 12px;
    // color: $lms_Primary_Blue;
}

.qurstion_color{
    background-color: #cccccc;
    padding-bottom: 0px;
}

.hr {
    margin-right: 5rem;
    margin-left: 5rem;
  }

// .submit-exam-btn {
//     width: 160px;
//     align-self: center;
//     margin: 10px 0px 30px 0px;
//     padding: 5px 0px 5px 0px;
//     border-radius: 10px;
//     border: 1px solid #0A233E;
//     color: #0A233E;
//     background-color: white;
// }
// .submit-exam-btn:hover {
//     background-color: #0A233E;
//     border: none;
//     color: white;
//   }

  .question_desc {
    color: black;
    font-size: 24px;
    font-weight: 800;
    text-transform: none;
  }

  .score_main {
    text-align: center;
    padding: 35px 0;
}
p.score_title {
    font-size: 40px;
    font-weight: 400;
}
p.score_title {
    font-size: 40px;
    font-weight: 400;
    border-bottom: 3px solid $lms_Primary_Blue6;
    display: inline-block;
    color: $lms_Primary_Blue6;
}
p.score_text_style {
    font-size: 18px;
    font-weight: 400;
}
p.final_score.score_text_style {
    font-size: 40px;
    font-weight: 400;
    // color: #0B8A00;
    color: #f70000;
}
p.passing_score_text_style {
    font-size: 18px;
    font-weight: 400;
}
p.final_score.passing_score_text_style {
    font-size: 40px;
    font-weight: 400;
    color: #0B8A00;
    // color: #f70000;
}
.score_btn_cstm_style {
    display: inline-block;
    width: 170px;
    padding: 7px 0;
    margin: 0 17px 0 0;
    font-size: 16px;
    font-weight: 700;
    border-radius: 6px;
}
button.retake_btn {
    border: 2px solid $lms_Primary_Blue6;
    background: #fff;
    color: #0D2F55;
}
button.continue_btn {
    background: $lms_Primary_Blue6;
    color: #fff;
}
p.view_correct_ans_link {
    font-size: 16px;
    font-weight: 700;
    color: #0D2F55;
    cursor: pointer;
    // Uncomment
    width: 15%;
    margin: 17px auto 0;
    border-bottom: 2px solid black;
}
p.giveFeedback {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background: #1B6098;
    cursor: pointer;
    margin: 17px auto 0;
    padding: 8px 18px;
    border-radius: 6px;
    border: 1.5px solid black;
}
// New code
.view_ans_feedback_btn {
    display: inline-flex;
    align-items: center;
    gap: 45px;
    margin-top: 30px;
}


.notselectedBtn {
    margin: 10px 0;
    background: $lms_Greyscale_Offgray;
    color: $lms_Greyscale_Black;

    &::before {
        /* >> Symbol you want to use: */
        content: "\f111";
        /* >> Name of the FA free font (mandatory), e.g.:
               - 'Font Awesome 5 Free' for Regular and Solid symbols;
               - 'Font Awesome 5 Pro' for Regular and Solid symbols (Professional License);
               - 'Font Awesome 5 Brand' for Brands symbols. */
        font-family: 'Font Awesome 5 Free';
        /* >> Weight of the font (mandatory):
               - 400 for Regular and Brands symbols;
               - 900 for Solid symbols;
               - 300 for Light symbols. */
        font-weight: 900;

        /* >> Optional styling: */
        display: inline-block;
        margin-right: 20px;
        /* ... */
    }

}
li.description_design{
    // text-transform: math-auto;
    // &:first-letter{
    //     text-transform: uppercase;

    // }

    text-transform: initial;
}

// .descriptions_design{
//     text-transform: lowercase;
//     &:first-letter{
//         text-transform: uppercase !important;

//     }
// }
label.form-check-label{
    text-transform: math-auto;
}

.selectedBtn {
    margin: 10px 0;
    background: $lms_Accent_Green;
    color: $lms_Greyscale_White;

    &::before {
        /* >> Symbol you want to use: */
        content: "\f058";
        /* >> Name of the FA free font (mandatory), e.g.:
               - 'Font Awesome 5 Free' for Regular and Solid symbols;
               - 'Font Awesome 5 Pro' for Regular and Solid symbols (Professional License);
               - 'Font Awesome 5 Brand' for Brands symbols. */
        font-family: 'Font Awesome 5 Free';
        /* >> Weight of the font (mandatory):
               - 400 for Regular and Brands symbols;
               - 900 for Solid symbols;
               - 300 for Light symbols. */
        font-weight: 900;

        /* >> Optional styling: */
        display: inline-block;
        margin-right: 20px;
        /* ... */
    }
}


// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
    .page_inner.exam {
        padding: 0px;
    }
    .exam_banner {
        padding: 6px;
    }
    .exam_banner img {
        height: auto;
        border-radius: 20px;
    }
    .instraction_modal_inner {
        padding: 0 10px;
    }
    .modalpagetitle {
        font-size: 17px;
    }
    .ins_order li {
        margin: 6px 0 0 0;
        font-size: 14px;
    }
    .modal-footer {
        justify-content: center;
    }
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xxs() {
    p.score_title {
        font-size: 20px;
        border-bottom: 1.5px solid #0D2F55;
    }
    p.score_text_style {
        margin: 4px 0 0px 0;
    }
    p.passing_score_text_style {
        margin: 4px 0 0px 0;
    }
    p.final_score.score_text_style {
        font-size: 27px;
    }
    p.final_score.passing_score_text_style {
        font-size: 27px;
    }
    .score_btn_cstm_style {
        width: 120px;
        padding: 6px 0;
        font-size: 13px;
        margin: 0;
        font-weight: 400;
    }
    button.retake_btn {
        margin-right: 10px;
    }
    .score_button_sec {
        margin-top: 25px;
    }
    p.view_correct_ans_link {
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        width: 57%;
        margin: 17px auto 0;
        border-bottom: 1.5px solid black;
    }
}
@include screen-xs() {
    p.score_title {
        font-size: 20px;
        border-bottom: 1.5px solid #0D2F55;
    }
}
// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {
  
}
// Tablets and small desktops /*media screen for maximum width 992px*/
@include screen-md() {
  
}
// Large tablets and desktops /*media screen for maximum width 1024*/
@include screen-lg() {
   
}
// Large tablets and desktops /*media screen for maximum width 1400*/
@include screen-xl() {

}
@include screen-xxl() {

}