.quiz_box_main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 20px;
    cursor: pointer;
}
.quiz_right {
    display: flex;
    gap: 25px;
}
.quiz_arrow_icon i {
    font-size: 20px;
}

.ppt_css{
    top:0px;left:0px;width:100%;height:579px;position:relative;margin-top:-583px;
}