@import "../../../template/all.scss";

.static_reminder_item {
  display: flex;
  gap: 13px;
  align-items: center;
}
.static_reminder {
  padding: 10px 9px;
  background: beige;
  margin: 7px 7px 0px 7px;
  border-radius: 8px;
}
p.static_reminder_text {
  margin: 0;
  font-size: 13px;
  text-transform: none;
}
i.static_icon {
  font-size: 13px;
}
span.static_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1b6098;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50px;
}
.search_notification_sec {
  display: flex;
  align-items: center;
  gap: 10px;
}
.statistic_box {
  border: 2px solid #f1f1f1;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}
.page_inner {
  background: #fff;
  border-radius: 15px;
  padding: 15px;
}
span.dash_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 72px;
  // background: #F13B23;
  border-radius: 50px;
  color: #fff;
  font-size: 31px;
}
h6.stat_no {
  font-size: 55px;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

p.stat_title {
  font-size: 24px;
  color: #000000;
  font-weight: 400;
}
.section_title p {
  font-size: 20px;
  font-weight: 500;
  margin: 7px 0 10px 0;
  margin: 7px 0 15px 0;
}

// Header
.dash_header {
  margin: 5px 0 25px 0;
}
p.login_user_name {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
p.log_msg {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}
.serach_sec {
  position: relative;
}
input.serch_input {
  border: 1px solid #e7eae9;
  padding: 5px;
  padding-right: 34px;
  border-radius: 9px;
  padding-left: 10px;
}
input.serch_input:focus-visible {
  outline-offset: 0px;
  border: 1.5px solid #0d4c88;
  outline: 0;
}

span.search_icon {
  position: absolute;
  right: 12px;
  top: 6px;
  font-size: 17px;
  color: #000000;
}
.notification_bell {
  font-size: 18px;
  margin-left: 8px;
}
p.login_time {
  margin: 0;
  text-align: right;
}
span.log_time {
  font-size: 22px;
  font-weight: 500;
}
p.login_time {
  font-size: 18px;
  font-weight: 400;
}
.cstm_course_card {
  border-radius: 20px;
}

// Recent Course
.course_card_body .card-title {
  font-size: 16px;
  font-weight: 500;
  height: 40px;
}
.course_img {
  height: 217px;
  border-radius: 15px;
}
.content_img {
  width: 100%;
  height: auto;
}
p.module_details {
  margin: 0 0 10px 0;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: #1B6098;
  transition: var(--bs-progress-bar-transition);
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: 7px;
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}
.course_status {
  margin: 8px 0;
  padding: 0;
}
button.card_continue_btn {
  margin: 25px auto 0;
  display: block;
  border: none;
  background: #1B6098;
  color: #fff;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 27px;
  &.disable {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.card_complete_btn {
  margin: 25px auto 0;
  display: block;
  border: none;
  background: #fff;
  color: #1B6098;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 27px;
  border: 1px solid #1B6098;
}
span.clock_icon {
  color: #9ca3af;
  font-size: 13px;
  margin-left: 10px;
}
span.expired-txt {
  color: red;
  font-size: 13px;
  margin-left: 10px;
}

.newCourse_main {
  margin-top: 30px;
}
button.view_course_btn {
  border: none;
  background: #9ca3af;
  color: #fff;
  border-radius: 19px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  width: 78px;
  padding: 7px 0;
  position: relative;
  top: -18px;
}
.view_all_btn_area {
  text-align: center;
}
span.btn_border {
  border-bottom: 2px solid #f2f2f2;
  display: block;
  position: relative;
  margin-top: 30px;
}

// Reminder
.reminder_main {
  border: 2px solid #f3f4f6;
  border-radius: 10px 10px 10px 10px;
  position: relative;
  margin-top: -28px;
}
h5.reminder_title {
  text-align: center;
  background: #f3f4f6;
  padding: 8px;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  border-radius: 5px 5px 0 0;
}
.reminder_box {
  // Uncomment
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // Uncomment
  // padding: 10px 19px 10px 19px;
  border-bottom: 2px solid #f3f4f6;
}
.reminder_left {
  display: flex;
  align-items: center;
}
span.reminder_icon {
  // background: #FFCD00;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
}
span.reminder_icon.notify_course {
  background: #46D8BF;
}
span.reminder_icon.notify_exam {
  background: #1B6098;
}
h5.reminder_text {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-transform: none;
}
.reminder_for p {
  margin: 1px 0 0 0;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
span.navigate_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0b8a00;
  color: #fff;
  height: 22px;
  width: 22px;
  border-radius: 50px;
  font-size: 12px;
}
.reminder_content {
  overflow-y: auto;
  max-height: 328px;
}

// Leaderboard
.leaderboard_sub_heading {
  display: flex;
  justify-content: space-between;
  margin-top: 19px;
  margin-bottom: 20px;
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
  @include screen-xxs() {
    flex-direction: column;
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    flex-direction: column;
  }
}
select.leaderBoard_select {
  border: none;
  border-bottom: 1px solid #d1d5db;
  border-radius: 0;
  font-size: 16px;
  color: #9ca3af;
}
label.leaderBoard_select_label {
  font-size: 16px;
  font-weight: 500;
  margin: 0px 0 0px 0;
}
.leaderBoard_main {
  margin-top: 8px;
}
.leaderboard_title h5 {
  font-size: 20px;
  font-weight: 700;
}
.leaderBoard_left h5 {
  font-size: 17px;
  font-weight: 700;
  margin: 0;
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
  @include screen-xxs() {
    margin-bottom: 25px;
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    margin-bottom: 25px;
  }
}
img.leader_card_bg {
  position: relative;
}
// .profile_pic_main {
//   position: absolute;
//   top: 33px;
//   left: 0;
//   right: 0;
//   text-align: center;
// }
.nodata_found_main {
  width: 60%;
  margin: 0 auto;
}
.card.leader_card {
  border: none;
  padding-bottom: 25px;
}
.leaderBoard_content_card .row {
  align-items: center;
}
.view_all {
  text-align: center;
}
span.leader_batch {
  position: absolute;
  bottom: -32px;
  text-align: center;
  left: 0;
  right: 0;
}
img.leder_profile {
  position: relative;
  background: #75d3fb;
  height: 64px;
  width: 64px;
  border-radius: 50%;
}
span.leader_batch img {
  height: 50px;
  width: 50px;
}
h5.leader_name {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}
p.leader_rank {
  font-size: 15px;
  font-weight: 500;
  color: #f56421;
  margin: 0;
}
p.card_content {
  margin: 3px 0 0 0;
  font-weight: 500;
  font-size: 14px;
}
span.d_value {
  color: $lms_Primary_Blue6;
}
.cstm_card_body_style {
  padding: 5px;
}
.complete_module_text {
  font-size: 14px;
  font-weight: 400;
  text-transform: math-auto;
}
.leader_left_inner {
  border: 1px solid #d9d9d9;
  padding: 15px;
  border-radius: 12px;
  @include screen-xxs() {
    margin-top: 30px;
  }
  @include screen-xs() {
    margin-top: 30px;
  }
}
.timespend_title h5 {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-bottom: 48px;
  margin-top: 22px;
}

// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
  input.serch_input {
    width: 100%;
  }
  .search_notification_sec {
    margin-top: 12px;
  }
  p.login_time {
    text-align: left;
    font-size: 15px;
    margin-top: 10px;
  }
  span.log_time {
    font-size: 17px;
  }
  h6.stat_no {
    font-size: 30px;
  }
  span.dash_icon_wrapper {
    height: 50px;
    width: 50px;
    font-size: 19px;
  }
  p.stat_title {
    font-size: 20px;
    margin: 0;
  }
  .statistic_box {
    margin-bottom: 20px;
  }
  .newCourse_main {
    margin-top: 15px;
  }
  .cstm_course_card {
    margin-bottom: 20px;
  }
  .section_title p {
    font-size: 18px;
  }
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xs() {
}
// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {
}
// Tablets and small desktops /*media screen for maximum width 992px*/
@include screen-md() {
  h6.stat_no {
    font-size: 40px;
  }
}
// Large tablets and desktops /*media screen for maximum width 1024*/
@include screen-lg() {
}
// Large tablets and desktops /*media screen for maximum width 1400*/
@include screen-xl() {
  h6.stat_no {
    font-size: 35px;
  }
  .statistic_box {
    padding: 8px 20px;
  }
  span.dash_icon_wrapper {
    height: 60px;
    width: 60px;
    font-size: 26px;
  }
  p.stat_title {
    font-size: 22px;
  }
  .reminder_content {
    overflow-y: auto;
    max-height: 286px;
  }
  .profile_pic_main {
    top: 18px;
  }
  span.reminder_icon {
    height: 35px;
    width: 35px;
    font-size: 16px;
    margin-right: 8px;
  }
  // Uncomment
  // .reminder_box {
  //   padding: 10px 19px 10px 11px;
  // }

  .reminder_box {
    padding: 0px 0px 7px 0px;
}

  h5.reminder_text {
    font-size: 16px;
  }
  span.navigate_icon {
    height: 20px;
    width: 20px;
    font-size: 10px;
  }
  h5.reminder_title {
    font-size: 18px;
  }
}
@include screen-xxl() {
}
.leaderBoard_content_card {
  .card {
    height: 100%;
  }
}
.leaderBoard_content_card {
  @include screen-xxxs() {
    .col-xl-4 {
      margin-bottom: 20px;
    }
  }

  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
  @include screen-xxs() {
    .col-xl-4 {
      margin-bottom: 20px;
    }
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    .col-xl-4 {
      margin-bottom: 20px;
    }
  }
}
