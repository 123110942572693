@import './../../../template/all.scss';
.ag-theme-alpine {
  --ag-header-height: 30px;
  --ag-header-foreground-color: black;
  --ag-header-background-color: rgba(0, 0, 0, 0.25);
  --ag-header-cell-hover-background-color: #F0F7FF;
  
}

.ag-cell.course_name_column {
  line-height: 22px;
}
/* .ag-theme-alpine .ag-header {
  font-family: cursive;
}
.ag-theme-alpine .ag-header-group-cell {
  font-weight: normal;
  font-size: 22px;
}
.ag-theme-alpine .ag-header-cell {
  font-size: 18px;
} */
.ag-header-cell{
  background-color: #F0F7FF;
  color: black;
}

.ag-root-wrapper{
  border-radius: 10px;
}

.ag-row-even {
  background-color: green;
}

.ag-row {
  &.ag-row-odd {
    background-color: #FBFBFB;
    &:hover {
      background-color: #FBFBFB;
    }
  }

  &.ag-row-even {
    background-color: #F1F1F1;
    &:hover {
      background-color: #F1F1F1;
    }
  }
}

.ag-cell-wrapper{
  height: 55px;
}
.report_table_title {
  font-size: 22px;
  font-weight: 500;
}
.ag-theme-alpine .ag-paging-panel > * {
  margin: 0 4px;
}


// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
  .page_inner.reports_main {
    padding: 10px 15px 10px 15px;
}
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xs() {
   
}
// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {
  
}
// Tablets and small desktops /*media screen for maximum width 992px*/
@include screen-md() {
  
}
// Large tablets and desktops /*media screen for maximum width 1024*/
@include screen-lg() {
   
}
// Large tablets and desktops /*media screen for maximum width 1400*/
@include screen-xl() {

}
@include screen-xxl() {

}