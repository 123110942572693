@import 'font';
@import 'color';
@import 'buttons';
@import 'points';
@import 'form';

.basefont {
    color: $lms_Primary_Blue;
    font-family: $lms_Regular;
}

.redtext {
    color: $lms_Primary_Red;
}

.redBlue {
    color: $lms_Primary_Blue;
}

.pro-icon {
    width: 30px;

    img {
        width: 100%;
        filter: invert(1);
    }

}