//FC4444
// Primary_Blue
$lms_Primary_Blue: #0D4C88;
$lms_Primary_Blue2:#0D4C88;
$lms_Primary_Blue3:#0D4C88;
$lms_Primary_Blue4:rgba(73, 124, 182, 1);
$lms_Primary_Blue5:rgba(83, 183, 232, 1);
// New
$lms_Primary_Blue6: #1B6098;
$lms_Primary_Blue_secondary: #46D8BF;

$lms_Main_bg: #D9D9D9;

// Primary_Red
$lms_Primary_Red:#FA8704;

$lms_Primary_Red5:rgba(196, 20, 37, 1);

$lms_Primary_Red2:rgba(239, 65, 35, 1);

$lms_Primary_Red3:rgba(241, 90, 34, 1);

$lms_Primary_Red4:rgba(243, 112, 50, 1);


// Greyscale
$lms_Greyscale_White:#ffffff;
$lms_Greyscale_Black:#000000;
$lms_Greyscale_Gray:#999999;
$lms_Greyscale_Offgray:#cccccc;
$lms_Greyscale_Offwhite:#ebebec;

//Accent

$lms_Accent_Yellow: #F7B917;
$lms_Accent_Green:#32B44A;
$lms_Accent_Info:#44B5C0;


// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;


// stylelint-disable selector-no-qualifying-type

//
// Base styles
//