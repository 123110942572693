/* .page_main {
    background: #D9D9D9;
    padding: 15px;
} */
@import "./../../../template/all.scss";

.cetf_banner img {
  width: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
/* font-family: 'Ubuntu', sans-serif; */

body {
  font-family: "Ubuntu", sans-serif;
}
.page_inner {
  background: #fff;
  border-radius: 15px;
}
.certificate_list_outer {
  padding: 10px 12px;
}
.certificate_list_inner {
  background: #ececec;
  margin: 17px 0;
  border-radius: 15px;
  padding: 32px;
}
.certificate_list_image {
  text-align: center;
}
h4.certificate_title_details {
  margin: 0;
  font-size: 40px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  color: #000000;
  margin: 7px 0px;
}
p.certificate_module_details {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}
p.certificate_holder_details {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  margin: 0;
  margin-bottom: 20px;
}
button.cer_preview_btn {
  display: block;
  width: 150px;
  text-align: center;
  border: none;
  border-radius: 50px;
  background: #d9d9d9;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  padding: 5px 0;
}

button.cer_download_btn {
  display: block;
  text-align: center;
  border: none;
  border-radius: 6px;
  background: $lms_Primary_Blue6;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 9px;
}
.certificate_right_details {
  margin-top: 25px;
}

// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
  h4.certificate_title_details {
    font-size: 20px;
  }
  p.certificate_module_details {
    font-size: 14px;
  }
  p.certificate_holder_details {
    font-size: 15px;
  }
  .certificate_right_details {
    margin-top: -32px;
  }
  .certificate_right_details.cer_downlo {
    margin-top: 22px;
  }
  p.cer_course_name {
    margin: 0;
  }
  p.certificate_complte_date {
    margin: 4px 0 0 0;
  }
  p.certificate_score {
    margin: 5px 0 14px 0;
  }
  button.cer_download_btn {
    font-size: 14px;
    padding: 9px;
  }
  button.cer_preview_btn {
    width: 115px;
    padding: 7px 0;
    font-size: 14px;
  }
  .cetf_banner img {
    border-radius: 10px;
  }
  .certificate_list_outer {
    padding: 0;
  }
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xs() {
}
// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {
}
// Tablets and small desktops /*media screen for maximum width 992px*/
@include screen-md() {
}
// Large tablets and desktops /*media screen for maximum width 1024*/
@include screen-lg() {
}
// Large tablets and desktops /*media screen for maximum width 1400*/
@include screen-xl() {
  h4.certificate_title_details {
    font-size: 28px;
  }
  p.certificate_module_details {
    font-size: 17px;
  }
  p.certificate_holder_details {
    font-size: 17px;
  }
}
@include screen-xxl() {
}
.cstm_certificate_outer {
  .card-body {
    .pg-viewer-wrapper {
      // Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
      @include screen-xxs() {
        canvas {
          width: 100%;
        }
      }
      // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
      @include screen-xs() {
        canvas {
          width: 100%;
        }
      }
    }
  }
}
