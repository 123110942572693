@import "../../template/all.scss";

.logo {
    margin: auto;
    text-align: center;
}

.row_h100 {
    height: 100vh;
}

.bg1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg3 {
    background: red;
}

.logintext {
    color: $lms_Greyscale_Black;
    font-family: $lms_Medium;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: center;

}