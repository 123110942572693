// stylelint-disable value-keyword-case
@import 'color';

$lms_Bold:"Signika-Bold";
$lms_Light:"Signika-Light";
$lms_Medium:"Signika-Medium";
$lms_Regular:"Signika-Regular";
$lms_SemiBold:"Signika-SemiBold";



@font-face {
  font-family: $lms_Bold;
  src: url('font/static/Signika-Bold.ttf');
}

@font-face {
  font-family: $lms_Light;
  src: url('font/static/Signika-Light.ttf');
}

@font-face {
  font-family: $lms_Medium;
  src: url('font/static/Signika-Medium.ttf');
}

@font-face {
  font-family: $lms_Regular;
  src: url('font/static/Signika-Regular.ttf');
}

@font-face {
  font-family: $lms_SemiBold;
  src: url('font/static/Signika-SemiBold.ttf');
}

