@import "../../../template/all.scss";

.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: #ffffff;
  min-width: 270px;
  height: 470px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border: 1px solid $lms_Primary_Blue;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

// .post-module:hover,
// .hover {
//     -webkit-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
//     -moz-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
//     box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
// }

.post-module .thumbnail {
  height: 217px;
  overflow: hidden;
  background: #162f6624;
  // border: 2px solid #F4F4F4;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.post-module .exam_thumbnail img {
  height: 217px;
  width: 100%;
  overflow: hidden;
  background: #162f6624;
  // border: 2px solid #F4F4F4;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.cardpositions {
  position: relative;
  z-index: 999;
}

.post-module .thumbnail .date {
  // position: absolute;
  // top: 20px;
  // right: 20px;
  // z-index: 1;
  // // background: $lms_Primary_Red;
  // width: 85px;
  // height: 85px;
  // padding: 12.5px 0;
  // border-radius: 100%;
  // color: #FFFFFF;
  // font-weight: 700;
  // text-align: center;
  // -webkti-box-sizing: border-box;
  // box-sizing: border-box;
  position: absolute;
  top: 180px;
  right: 2px;
  z-index: 1;
  // background: $lms_Primary_Red;
  width: 185px;
  height: 85px;
  padding: 12.5px 0;
  // border-radius: 100%;
  color: $lms_Primary_Blue;
  font-weight: 700;
  text-align: center;
  -webkti-box-sizing: border-box;
  box-sizing: border-box;
  font-style: italic;
  text-transform: lowercase;
}

.post-module .thumbnail .date1 {
  position: absolute;
  top: 189px;
  right: 2px;
  z-index: 1;
  // background: $lms_Primary_Red;
  width: 185px;
  height: 85px;
  padding: 12.5px 0;
  // border-radius: 100%;
  color: $lms_Primary_Blue;
  font-weight: 700;
  text-align: center;
  -webkti-box-sizing: border-box;
  box-sizing: border-box;
  font-style: italic;
  text-transform: lowercase;
}

.post-module .thumbnail .index {
  position: absolute;
  left: 30px;
  z-index: 1;
  background: #e84c3d;
  width: 40px;
  height: 30px;
  // padding: 12.5px 0;
  // border-radius: 100%;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  -webkti-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 20px;
}

.post-module .thumbnail .date .day {
  font-size: 18px;
}

.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}

.post-module .thumbnail img {
  display: flex;
  width: 115px;
  transition: all 0.3s linear 0s;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  position: relative;
  top: 11%;
  transition: all 0.3s linear 0s;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.post-module .post-content {
  // position: absolute;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  word-break: break-all;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.post-module .post-content .category {
  position: absolute;
  top: -25px;
  left: 0;
  background: #a7acb3;
  padding: 10px 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-transform: lowercase;
}

.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: $lms_Primary_Blue;
  font-size: 20px;
  font-weight: 600;
}

// .post-module .post-content .title1 {
//     position: absolute;
//     top: 20px;
//     right: 50px;
//     padding: 0 0 10px;
//     color:$lms_Primary_Blue;
//     font-size: 20px;
//     font-weight: 700;
// }

.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #e74c3c;
  font-size: 14px;
  font-weight: 400;
}

.post-module .post-content .description {
  display: none;
  color: #666666;
  font-size: 16px;
  line-height: 1.8em;
}

.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #999999;
}

.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}

.post-module .post-content .post-meta a {
  color: #999999;
  text-decoration: none;
}

.hover .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}

.title-head {
  // position: absolute;
  top: 70px;
  // right:110px;
  margin: 0;
  padding: 0 0 10px;
  color: $lms_Primary_Blue;
  font-size: 20px;
  font-weight: 600;
}

.container {
  max-width: 800px;
  min-width: 640px;
  margin: 0 auto;
}
.lesson_content_main {
  .container {
    @include screen-xxs() {
      min-width: 100%;
      max-width: 100%;
      margin: 0 auto;
      canvas{
        width: 100%;
      }
    }
    // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
    @include screen-xs() {
      min-width: 100%;
      max-width: 100%;
      margin: 0 auto;
      canvas{
        width: 100%;
      }
    }
    @include screen-sm() {
      min-width: 100%;
      max-width: 100%;
      margin: 0 auto;
      canvas{
        width: 100%;
      }
    }
  }
}

.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}

.container .column {
  width: 50%;
  padding: 0 25px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}

.container .column .demo-title {
  margin: 0 0 15px;
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.container .info {
  width: 300px;
  margin: 50px auto;
  text-align: center;
}

.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.Questionhead {
  background: #e84c3d;
  color: $lms_Greyscale_White;
  display: inline-block;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 10px;

  &::before {
    background: url("../../../template/img/QA.png");
  }
}

.Questionrow {
  border-bottom: 2px solid #e84c3d;
}

.Quesbody {
  display: flex;
  align-content: center;
  align-items: center;

  img {
    width: 50px;
  }

  // h5 {
  //     color: $lms_Primary_Blue;
  //     font-weight: bold;
  // }
}

.container .info span {
  color: #666666;
  font-size: 12px;
}

.container .info span a {
  color: #000000;
  text-decoration: none;
}

.container .info span .fa {
  color: #e74c3c;
}

@import "../../layout/layout.scss";

.agunderline {
  text-decoration: underline;
}

.custom-tooltip {
  overflow: visible;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.ag-theme-alpine
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-ltr
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-ltr
  .ag-has-focus
  .ag-full-width-row.ag-row-focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-alpine
  .ag-rtl
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-rtl
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-rtl
  .ag-has-focus
  .ag-full-width-row.ag-row-focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: none !important;
  border-color: transparent !important;
  border-color: var(--ag-range-selection-border-color, #2196f3);
  outline: initial;
}

.dragarea {
  border: 3px dashed $lms_Greyscale_Gray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 15px;
  width: 100%;
  height: 350px;
  text-align: center;

  .filetext {
    position: absolute;
    width: 40%;
    height: 50%;

    // // Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
    // @include screen-xxs() {
    //     width: 40%;
    //     height: 50%;

    // }

    // // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
    // @include screen-xs() {
    //     width: 40%;
    //     height: 50%;
    // }

    // // Small tablets (portrait view)/*media screen for maximum width 768*/
    // @include screen-sm() {
    //     width: 40%;
    //     height: 50%;
    // }

    // // Tablets and small desktops /*media screen for maximum width 992px*/
    // @include screen-md() {
    //     width: 80%;
    //     height: 29%;
    // }

    // // Large tablets and desktops /*media screen for maximum width 1024*/
    // @include screen-lg() {
    //     width: 57%;
    //     height: 30%;
    // }

    // // Large tablets and desktops /*media screen for maximum width 1400*/
    // @include screen-xl() {
    //     width: 40%;
    //     height: 50%;
    // }

    // @include screen-xxl() {
    //     width: 40%;
    //     height: 50%;
    // }
  }

  .header {
    font-family: $lms_Bold;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;
    // margin-top: 10px;
    color: $lms_Primary_Blue;
  }

  .subheader {
    font-family: $lms_SemiBold;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */
    color: $lms_Primary_Blue;
    letter-spacing: 0.02em;
  }

  .subheadermsg {
    font-family: $lms_SemiBold;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: $lms_Primary_Red;
  }
}

.Filestyle {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  opacity: 0;
}

.dragarea.active {
  border: 2px solid $lms_Greyscale_Gray;
}

.case {
  text-transform: lowercase;
}

.dot-online {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.dot-offline {
  height: 10px;
  width: 10px;
  background-color: #e74c3c;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.status {
  font-style: italic;
  position: absolute;
  right: 20px;
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
  .cardpositions {
    z-index: 99;
  }
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xs() {
  .cardpositions {
    z-index: 99;
  }
}
// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {
  .cardpositions {
    z-index: 99;
  }
}
