@import '../../../template/all.scss';

.ts-course-category .single-course-category .course-media {
    margin-bottom: 25px
}

.pg-viewer-wrapper {
    overflow-y: unset !important;
    img{
        width: auto !important;
        max-width: 100%;
    }
}

.photo-viewer-container {
    width: unset !important;
    height: unset !important;
}

.photo-viewer-container>img {
    width: unset !important;
    height: unset !important;
}

.ts-course-category .single-course-category .course-media img {
    -o-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -ms-transition: all .4s ease;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    width: 120px;
    height: 120px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%
}


.ts-course-category .single-course-category:hover .course-media img {
    -webkit-transform: scale(.9);
    -ms-transform: scale(.9);
    transform: scale(.9)
}


.ts-course-category.grid-style3 .single-course-category .course-media {
    margin-bottom: 0;
    margin-right: 25px
}

.course-category-title {
    font-family: $lms_Medium;
    color: $lms_Primary_Blue;
    font-size: 20px;

}

.details {
    display: flex;
    align-items: center;
}

.Materialsbtn {

    /* font-family: "Signika-SemiBold"; */
    /* color: #ffffff; */
    /* text-align: center; */
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    color: $lms_Primary_Blue;
    ;
    min-width: 0;
    /* height: 30px; */
    /* font-weight: 700; */
    font-size: 30px;

    &:hover {
        color: $lms_Primary_Red;
        ;
    }

}

.Materialsbody {
    padding: 19px 0;
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
    border-top: 6px solid $lms_Primary_Red;

    &:hover {

        border-top: 6px solid #003366;
    }
}

.pagetitlename {
    font-family: $lms_Bold;
    font-weight: 700;
    font-size: 20px;
    line-height: 0;
    /* width: 100%; */
    padding: 15px 11px;
    color: $lms_Greyscale_White;
    background: $lms_Accent_Green;
    display: inline-block;
    border-radius: 5px;
}




@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');

:root {
    --color-bg: #EEEDEB;
    --color-title: #0E1C4E;
    --color-summary-1: #FFF6EE;
    --color-summary-1-highlight: #FFC48B;
    --color-summary-2: #FAFAFF;
    --color-summary-2-highlight: #B4B3FF;
    --color-summary-3: #F4F4F4;
    --color-summary-3-highlight: #E84C3D;
    --font-ibm-plex-sans: "IBM Plex Sans", sans-serif;
}

.Lessondiv {
    display: flex;

    .cardbody {
        width: 100%;
    }
}

.Lessoncard {

    max-width: 425px;
    width: 100%;
    border-radius: 12px;
    margin-right: 15px;
    margin-bottom: 16px;

    h1 {
        font-family: var(--font-ibm-plex-sans);
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 1.2;
        color: var(--color-title);
        margin-bottom: 20px;
    }

    details {
        display: flex;

        border-radius: 5px;
        overflow: hidden;
        background: rgba(0, 0, 0, .05);
        border-left: 15px solid gray;
        padding: 15px;

        & {
            margin-top: 15px;
        }

        &.details1 {
            --highlight: var(--color-y-1-highlight);
            background: var(--color-summary-1);
            border-left-color: var(--color-summary-1-highlight);

            p {
                list-style-type: corona-warning;
            }

            &:hover {
                border-color: var(--color-summary-1-highlight);
            }

            button {
                position: absolute;
                right: 0;
                background: var(--highlight);
                padding: 6px;
                border-radius: 10px;
            }
        }

        &.details3 {
            --highlight: var(--color-summary-2-highlight);
            background: var(--color-summary-2);
            border-left-color: var(--color-summary-2-highlight);

            p {
                list-style-type: corona-info;
            }

            button {
                position: absolute;
                right: 0;
                background: var(--highlight);
                padding: 6px;
                border-radius: 10px;
            }
        }

        &.details3 {
            --highlight: var(--color-summary-3-highlight);
            background: var(--color-summary-3);
            border-left-color: $lms_Primary_Red;

            p {
                list-style-type: corona-alert;
            }

            button {
                position: absolute;
                right: 0;
                background: var(--highlight);
                padding: 6px;
                border-radius: 10px;
            }
        }

        summary,
        div {
            position: relative;
            flex-direction: row;
            align-content: center;
            justify-content: flex-start;
            font-family: $lms_Medium;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: var(--color-title);
            align-items: center;
            cursor: pointer;
            padding: 10px 11px;

            &::marker {
                display: none !important;
                color: transparent;
                display: none;
            }


            &::selection {
                background-color: var(--highlight);
            }
        }


        .leaderboard__del {
            padding: 0;

            span {
                font-size: 12px;
            }
        }

        // summary::before {
        //     cursor: pointer;
        //     position: absolute;
        //     display: inline-flex;
        //     width: 1rem;
        //     height: 1rem;
        //     left: 0rem;
        //     margin-right: .5rem;
        //     content: "";
        //     border: 2px solid;
        //     top: 15px;

        // }

        &[open] {
            summary {

                font-weight: 700;

                &::before {
                    transform: rotate(45deg);
                    content: "";
                    border-bottom: 2px solid green;
                    border-right: 2px solid green;
                    border-top: 0;
                    border-left: 0;
                    top: 15px;
                    /* border-right-style: hidden; */
                    width: 10px;
                }
            }
        }
    }
}

.leaderboard {
    max-width: 490px;
    width: 100%;
    border-radius: 12px;
    margin-right: 25px;

    header {
        --start: 15%;

        height: 130px;
        background-image: repeating-radial-gradient(circle at var(--start), transparent 0%, transparent 10%, rgba(54, 89, 219, .33) 10%, rgba(54, 89, 219, .33) 17%), linear-gradient(to right, #5b7cfa, #3659db);
        color: #fff;
        position: relative;
        border-radius: 12px 12px 0 0;
        overflow: hidden;

        .leaderboard__title {
            position: absolute;
            z-index: 2;
            top: 50%;
            right: calc(var(--start) * .75);
            transform: translateY(-50%);
            text-transform: uppercase;
            margin: 0;

            span {
                display: block;
            }

            &--top {
                font-size: 24px;
                font-weight: 700;
                letter-spacing: 6.5px;
            }

            &--bottom {
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 3.55px;
                opacity: .65;
                transform: translateY(-2px);
            }
        }

        .leaderboard__icon {
            fill: #fff;
            opacity: .35;
            width: 50px;
            position: absolute;
            top: 50%;
            left: var(--start);
            transform: translate(-50%, -50%);
        }
    }

    &__profiles {
        background-color: #fff;
        border-radius: 0 0 12px 12px;
        padding: 15px 15px 20px;
        display: grid;
        row-gap: 8px;
    }

    &__profile {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        align-items: center;
        padding: 10px 30px 10px 10px;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
        cursor: pointer;
        transition: transform .25s cubic-bezier(.7, .98, .86, .98), box-shadow .25s cubic-bezier(.7, .98, .86, .98);
        background-color: #fff;


    }

    &__picture {
        width: 50px;
        margin-top: 30px;
        height: 50px;

    }

    &__academic {
        width: 35px;
        height: 36px;

    }

    &__class {
        width: 30px;
        height: 30px;
        // margin-top: 10px;

    }

    &__division {
        width: 30px;
        height: 30px;
        // margin-top: 10px;

    }

    &__name {
        color: $lms_Primary_Blue;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.64px;
    }

    &__value {
        color: #35d8ac;
        font-weight: 700;
        font-size: 34px;
        text-align: center;

        &>span {
            opacity: .8;
            font-weight: 600;
            font-size: 13px;
            margin-left: 3px;
        }

        &:hover {
            transform: scale(1.2);
            box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
            border: 1px solid dotted;
            color: #35d8ac;
            border-radius: 25px;
        }
    }
}

.pictureicon {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, .05);

    .pictureimg {
        width: 65px;
        // border: 1px solid $lms_Primary_Red;
        border-radius: 10px;
    }
}


.leaderboard {
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, .4);
}

.Materialsbodys {
    width: 93%;
    text-align: center;
    border-radius: 15px;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid $lms_Primary_Blue;

    img {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .card-body {

        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        position: relative;
        z-index: 1;

        .card-title {
            margin-top: 8px;
            margin-bottom: 8px;
            font-family: $lms_SemiBold;
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: $lms_Primary_Blue;



        }

        .card-text1 {
            margin-top: 8px;
            margin-bottom: 8px;
            font-family: $lms_SemiBold;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 14px;
            letter-spacing: 0.06em;
            color: $lms_Greyscale_Gray;


        }
    }

    &:hover {
        &::before {
            content: "";
            // background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, $lms_Primary_Red 125%);
            // background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, $lms_Primary_Red 125%);
            // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, $lms_Primary_Red 90%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            border-radius: 10px;
            -webkit-animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            .card-body {


                .card-title {
                    color: $lms_Greyscale_Black;

                }

                .card-text1 {

                    color: $lms_Greyscale_Black;


                }
            }
        }
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-1 2:51:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-bottom {
    0% {
        -webkit-transform: scaleY(0.2);
        transform: scaleY(0.2);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }

    25% {
        -webkit-transform: scaleY(.4);
        transform: scaleY(.4);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }

    75% {
        -webkit-transform: scaleY(.8);
        transform: scaleY(.8);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }
}

@keyframes scale-up-ver-bottom {
    0% {
        -webkit-transform: scaleY(0.2);
        transform: scaleY(0.2);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }

    25% {
        -webkit-transform: scaleY(.4);
        transform: scaleY(.4);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }

    75% {
        -webkit-transform: scaleY(.8);
        transform: scaleY(.8);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }
}

.unlitno {
    display: flex;
    justify-content: center;
    color: $lms_Greyscale_White;
    position: absolute;
    right: 0;
    width: 100px;
    height: 75px;
    font-family: $lms_SemiBold;
    background: $lms_Primary_Blue;
    align-items: center;
    font-size: 25px;
}

.box-wrap {}

.box {

    transition: .2s all;
}

.box-wrap:hover .box {
    filter: blur(3px);
    opacity: .5;
    transform: scale(.98);
    box-shadow: none;
}

.box-wrap:hover .box:hover {
    transform: scale(1);
    filter: blur(0px);
    opacity: 1;
    box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}


/* The ribbon */

.overlay-ribbon {
    position: absolute;
    width: 30px;
    height: 30px;
    // z-index: 9999;
    // top: -1px;
    right: 40px;
    background: $lms_Primary_Red;
    /* padding: 5px 0; */
    /* background-image: radial-gradient(circle farthest-side at center top, #cbf3f0 0%, #2ec4b6 100%); */
    // border-top-right-radius: 16px;
    // border-top-left-radius: 8px;
    // border-radius: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center
}

.ribbon-content {
    // z-index: 99999;
    position: relative;
    margin: 0 5px;
}

.ribbon-content h3 {
    margin: 0;
    padding: 5px 0 5px 0;
    line-height: 16px;
    // font-family: $lms_Bold;
    color: $lms_Greyscale_White;
    text-align: center;
    font-size: 15px;
}

.ribbon-content>p,
.ribbon-content>p span {
    color: #24505a;
    margin: 0;
    padding: 0;
}

.ribbon-content p span {
    display: block;
}

.overlay-ribbon:before {
    content: "";
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 19px solid $lms_Primary_Red;
    border-right: 30px solid transparent;
     z-index: 997;
}

.overlay-ribbon:after {
    content: "";
    position: absolute;
    bottom: -14px;
    right: 0;
    width: 0;
    height: 0;
    border-top: 19px solid $lms_Primary_Red;
    border-left: 30px solid transparent;
    z-index: 998;

}

.overlay-ribbon1 {
    position: absolute;
    width: 35px;
    height: 30px;
    // z-index: 9999;
    top: -1px;
    right: -1px;
    background: $lms_Primary_Red;
    /* padding: 5px 0; */
    /* background-image: radial-gradient(circle farthest-side at center top, #cbf3f0 0%, #2ec4b6 100%); */
    border-top-right-radius: 15px;
    // border-top-left-radius: 8px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center
}

.ribbon-content {
    // z-index: 99999;
    position: relative;
    margin: 0 5px;
}

.ribbon-content h3 {
    margin: 0;
    padding: 5px 0 5px 0;
    line-height: 16px;
    // font-family: $lms_Bold;
    color: $lms_Greyscale_White;
    text-align: center;
    font-size: 15px;
}

.ribbon-content>p,
.ribbon-content>p span {
    color: #24505a;
    margin: 0;
    padding: 0;
}

.ribbon-content p span {
    display: block;
}

.overlay-ribbon1:before {
    content: "";
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 19px solid $lms_Primary_Red;
    border-right: 30px solid transparent;
     z-index: 997;
}

.overlay-ribbon1:after {
    content: "";
    position: absolute;
    bottom: -14px;
    right: 0;
    width: 0;
    height: 0;
    border-top: 19px solid $lms_Primary_Red;
    border-left: 30px solid transparent;
    z-index: 998;

}
.vp-center {
    text-align: inherit !important;
}

.contentdiv {
    width: 200px;
    height: 150px;
    text-align: center;

    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    margin: 5px 10px;
    padding: 9px;
    position: relative;
    box-shadow: 3px 3px #F4F4F4;

    &:last-child {
        &:after {
            content: "";
            position: absolute;
            top: 45%;
            left: 100%;
            width: 108px;
            border-top: 2px solid #fff;
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: 42%;
        left: 100%;
        width: 108px;
        // border-top: 14px solid;
    }

    &:nth-child(even) {
        // border-left: 5px solid #FFC48B;

        background-color: #F4F4F4
    }

    &:nth-child(odd) {
        // border-left: 5px solid #FFB3C0;
        background-color: #F4F4F4;
    }

    &:hover {
        background: #F4F4F4;
        color: $lms_Primary_Blue;
    }



    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 7px;
    }

}

.itemhover {
    cursor: pointer;
}

.vp-center {
    display: inline !important;
}

.frame {
    width: calc(100% - 20px);
    height: 681px;

    iframe {
        width: 100% !important;
        height: 100%;
    }
}

.activecontentdiv {
    width: 200px;
    height: 150px;
    text-align: center;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    margin: 5px 10px;
    padding: 9px;
    border-left: 5px solid $lms_Primary_Red;
    background: $lms_Primary_Blue;

    filter: drop-shadow(2px 4px 6px black);
    color: #fff;

    &:hover {
        background: $lms_Primary_Blue;
        color: #fff;
    }

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 10px;
    }


    .animation-container {
        display: block;
        position: relative;
        width: 800px;
        max-width: 100%;
        margin: 0 auto;

        .lightning-container {
            position: absolute;
            top: 50%;
            left: 0;
            display: flex;
            transform: translateY(-50%);

            .lightning {
                position: absolute;
                display: block;
                height: 12px;
                width: 12px;
                border-radius: 12px;
                transform-origin: 6px 6px;

                animation-name: woosh;
                animation-duration: 1.5s;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
                animation-direction: alternate;

                &.white {
                    background-color: white;
                    box-shadow: 0px 50px 50px 0px transparentize(white, 0.7);
                }

                &.red {
                    background-color: #fc7171;
                    box-shadow: 0px 50px 50px 0px transparentize(#fc7171, 0.7);
                    animation-delay: 0.2s;
                }
            }
        }


        .boom-container {
            position: absolute;
            display: flex;
            width: 80px;
            height: 80px;
            text-align: center;
            align-items: center;
            transform: translateY(-50%);
            left: 200px;
            top: -145px;

            .shape {
                display: inline-block;
                position: relative;
                opacity: 0;
                transform-origin: center center;

                &.triangle {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    transform-origin: 50% 80%;
                    animation-duration: 1s;
                    animation-timing-function: ease-out;
                    animation-iteration-count: infinite;
                    margin-left: -15px;
                    border-width: 0 2.5px 5px 2.5px;
                    border-color: transparent transparent #42e599 transparent;
                    animation-name: boom-triangle;

                    &.big {
                        margin-left: -25px;
                        border-width: 0 5px 10px 5px;
                        border-color: transparent transparent #fade28 transparent;
                        animation-name: boom-triangle-big;
                    }
                }

                &.disc {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background-color: #d15ff4;
                    animation-name: boom-disc;
                    animation-duration: 1s;
                    animation-timing-function: ease-out;
                    animation-iteration-count: infinite;
                }

                &.circle {
                    width: 20px;
                    height: 20px;
                    animation-name: boom-circle;
                    animation-duration: 1s;
                    animation-timing-function: ease-out;
                    animation-iteration-count: infinite;
                    border-radius: 100%;
                    margin-left: -30px;

                    &.white {
                        border: 1px solid white;
                    }

                    &.big {
                        width: 40px;
                        height: 40px;
                        margin-left: 0px;

                        &.white {
                            border: 2px solid white;
                        }
                    }
                }

                &:after {
                    background-color: rgba(178, 215, 232, 0.2);
                }
            }

            .shape {

                &.triangle,
                &.circle,
                &.circle.big,
                &.disc {
                    animation-delay: .38s;
                    animation-duration: 3s;
                }

                &.circle {
                    animation-delay: 0.6s;
                }
            }

            &.second {
                left: 485px;
                top: 155px;

                .shape {

                    &.triangle,
                    &.circle,
                    &.circle.big,
                    &.disc {
                        animation-delay: 1.9s;
                    }

                    &.circle {
                        animation-delay: 2.15s;
                    }
                }
            }
        }
    }

    @keyframes woosh {
        0% {
            width: 12px;
            transform: translate(0px, 0px) rotate(-35deg);
        }

        15% {
            width: 50px;
        }

        30% {
            width: 12px;
            transform: translate(214px, -150px) rotate(-35deg);
        }

        30.1% {
            transform: translate(214px, -150px) rotate(46deg);
        }

        50% {
            width: 110px;
        }

        70% {
            width: 12px;
            transform: translate(500px, 150px) rotate(46deg);
        }

        70.1% {
            transform: translate(500px, 150px) rotate(-37deg);
        }

        85% {
            width: 50px;
        }

        100% {
            width: 12px;
            transform: translate(700px, 0) rotate(-37deg);
        }
    }

    @keyframes boom-circle {
        0% {
            opacity: 0;
        }

        5% {
            opacity: 1;
        }

        30% {
            opacity: 0;
            transform: scale(3);
        }

        100% {}
    }

    @keyframes boom-triangle-big {
        0% {
            opacity: 0;
        }

        5% {
            opacity: 1;
        }

        40% {
            opacity: 0;
            transform: scale(2.5) translate(50px, -50px) rotate(360deg);
        }

        100% {}
    }

    @keyframes boom-triangle {
        0% {
            opacity: 0;
        }

        5% {
            opacity: 1;
        }

        30% {
            opacity: 0;
            transform: scale(3) translate(20px, 40px) rotate(360deg);
        }

        100% {}
    }

    @keyframes boom-disc {
        0% {
            opacity: 0;
        }

        5% {
            opacity: 1;
        }

        40% {
            opacity: 0;
            transform: scale(2) translate(-70px, -30px);
        }

        100% {}
    }


}
.batchimg{
    background-color: #b3b2b2 !important;
}

// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
    .Materialsbodys {
        width: 100%;
    }
    .Materialsbodys .card-body .card-title {
        font-size: 19px;
    }
    .Lessoncard details summary, .Lessoncard details div {
        display: block;
    }
    .contentdiv {
        width: 84%;
        margin: 12px 10px;
    }
    .activecontentdiv {
        width: 84%;
    }
    a.cstm_style {
        margin-left: 48px;
    }
    .overlay-ribbon1:after {
        z-index: 0;
    }
    .overlay-ribbon1:before {
        z-index: 0;
    }
    
    
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xs() {
    .Materialsbodys {
        width: 100%;
    }
    .Materialsbodys .card-body .card-title {
        font-size: 19px;
    }
    .Lessoncard details summary, .Lessoncard details div {
        display: block;
    }
    .contentdiv {
        width: 84%;
        margin: 12px 10px;
    }
    .activecontentdiv {
        width: 84%;
    }
    a.cstm_style {
        margin-left: 48px;
    }
    .overlay-ribbon1:after {
        z-index: 0;
    }
    .overlay-ribbon1:before {
        z-index: 0;
    }
    
    
}
// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {
    .Materialsbodys {
        width: 100%;
    }
    .Materialsbodys .card-body .card-title {
        font-size: 19px;
    }
    .Lessoncard details summary, .Lessoncard details div {
        display: block;
    }
    .contentdiv {
        width: 84%;
        margin: 12px 10px;
    }
    .activecontentdiv {
        width: 84%;
    }
    a.cstm_style {
        margin-left: 48px;
    }
    .overlay-ribbon1:after {
        z-index: 0;
    }
    .overlay-ribbon1:before {
        z-index: 0;
    }
    
}