@import '../../template/all';

.Headernav {
    background-color: $lms_Primary_Red5;
    height: 67px;
}

.profilebtn {
    border-radius: 24px;
    background: $lms_Greyscale_Gray;
    width: 50px;
    height: 50px;
}




.dropdownbtn {
    border: none;

    button {
        background-color: transparent;
        border: none;
        color: $lms_Greyscale_White;

        &:hover {
            background-color: transparent;
            color: $lms_Greyscale_White;
        }
    }

    div {
        min-width: 250px;
        padding: 0;
    }
}

.dropdownitme {
    padding: 15px;
    border-bottom: 1px solid;
}

.getUsername {
    color: $lms_Greyscale_White;
    padding: 16px;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
}