@import "./../../../template/all.scss";

.demo-book {
  background-color: #000000;
  width: auto;
  background-image: url("../../../template/img/pdf-bg.png");
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  background-size: contain;
  margin: 0 auto;
  height: 550px;
  .stf__block{
    left: 0px;
    // margin-top: -9px;
  }
  canvas {
    width: 100% !important;
    height: 550px !important;
  }
  @include screen-xxs() {
    box-shadow: none;
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    box-shadow: none;
  }
  @include screen-sm() {
    box-shadow: none;
  }
}
.demo-book-btn {
  margin-top: 20px;
  z-index: 100;
  position: relative;
  text-align: center;
  button {
    background-color: #0a233e;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 3px 6px;
    cursor: pointer;
    height: 25px;
    width: 25px;
    font-size: 13px;
    border-radius: 50%;
    text-align: center;
}
}
button.pagePrevious {
  margin: 0 0 0 8px;
}
.course_main .cstm_course_card {
  margin: 12px 0;
}

/* Module item */
.module_description {
  margin-top: 25px;
}
h5.description_title {
  font-size: 18px;
  font-weight: 500;
}
p.description_design {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}
.module_description p {
  font-size: 16px;
  font-weight: 400;
}
.reminder_main {
  border: 2px solid #f3f4f6;
  border-radius: 10px 10px 10px 10px;
  position: relative;
  margin-top: -28px;
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
  @include screen-xxs() {
    margin-top: 4px;
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    margin-top: 4px;
  }
  @include screen-sm() {
    margin-top: 4px;
  }
}
.reminder_left {
  width: 93%;
}
h5.reminder_title {
  text-align: center;
  background: #f3f4f6;
  padding: 8px;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  border-radius: 5px 5px 0 0;
}
.module_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 19px 10px 19px;
  background: #f4f4f4;
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
}
.new_module_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #f4f4f4;
  border-radius: 6px;
  margin-bottom: 25px;
  box-shadow: rgb(50 50 93 / 8%) 0px 6px 12px -2px, rgb(0 0 0 / 16%) 0px 3px 7px -3px;
  cursor: pointer;
}
.download_icon {
  cursor: pointer;
}
.module_icon {
  display: flex;
  gap: 15px;
}
.module_box::after {
  content: "";
  position: absolute;
  height: 98%;
  width: 21px;
  background-color: $lms_Primary_Blue_secondary;
  left: 0px;
  border-radius: 20px;
  top: 1px;
}
.module_for {
  margin-left: 20px;
  width: 100%;
}
/* .course_progress {
    margin-top: 9px;
    width: 85%;
} */
.pregress_main {
  margin-top: 9px;
  width: 85%;
}

span.progress_value {
  font-size: 15px;
  font-weight: 400;
  display: block;
  margin-top: 2px;
}
.reminder_left {
  display: flex;
  align-items: center;
}
/* span.reminder_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 38px;
    font-size: 20px;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
} */
span.reminder_icon.notify_course {
  background: #ffcd00;
}
span.reminder_icon.notify_exam {
  background: #f56421;
}
h5.reminder_text {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  .module_name{
    font-weight: 500;
  }
  .modules_description{
    font-size: 16px;
  }
}

.reminder_for p {
  margin: 1px 0 0 0;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
span.navigate_icon_ac {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $lms_Primary_Blue6;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  font-size: 15px;
  cursor: pointer;
}
.reminder_content {
  overflow-y: auto;
  max-height: 328px;
}

.bread-crumbs-back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MuiBreadcrumbs-li {
  margin: 0px;
  padding: 0px;
}

/* Module content view  */
.model_content_view_header .row {
  align-items: center;
}
.model_content_view_header .reminder_box {
  margin: 0;
}
button.next_btn {
  font-size: 18px;
  font-weight: 500;
  border: none;
  padding: 5px 27px;
  border-radius: 12px;
  background: #0d2f55;
  color: #fff;
}
.module_sec_title h5 {
  font-size: 20px;
  font-weight: 500;
  background: #f4f4f4;
  padding: 12px 10px;
  border-radius: 15px 15px 0 0;
}
.module_content_main {
  margin-top: 25px;
  border: 1px solid #9ca3af;
  border-radius: 15px;
}
.accordian_content {
  padding: 15px;
}
.accordian_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 30px;
}
.acc_head_right {
  display: flex;
  gap: 30px;
}

.acc_head_left {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.model_content_view_header .module_box {
  margin-bottom: 0;
}

.module_content_main .accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: none;
}
// .module_content_main .accordion {
//     --bs-accordion-color: var(--bs-body-color);
//     --bs-accordion-bg: var(--bs-body-bg);
//     --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
//     --bs-accordion-border-color: var(--bs-border-color);
//     --bs-accordion-border-width: var(--bs-border-width);
//     --bs-accordion-border-radius: var(--bs-border-radius);
//     --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
//     --bs-accordion-btn-padding-x: 1.25rem;
//     --bs-accordion-btn-padding-y: 1rem;
//     --bs-accordion-btn-color: var(--bs-body-color);
//     --bs-accordion-btn-bg: var(--bs-accordion-bg);
//     --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
//     --bs-accordion-btn-icon-width: 1.25rem;
//     --bs-accordion-btn-icon-transform: rotate(-180deg);
//     --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
//     --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23052c65%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
//     --bs-accordion-btn-focus-border-color: #86b7fe;
//     --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
//     --bs-accordion-body-padding-x: 1.25rem;
//     --bs-accordion-body-padding-y: 1rem;
//     --bs-accordion-active-color: var(--bs-primary-text-emphasis);
//     --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
//     margin-bottom: 10px;
// }

/* .pdf-viewer-container {
    max-height: 500px;
    overflow-y: scroll;
    width: 100%;
} */

.pdf-view {
  // max-height: 500px;
  // min-height: 500px;
  overflow-y: scroll;
  /* margin: 20px; */
  padding: 10px;
  border: solid 1px;
  padding: 20px 0px;
  border-radius: 15px;
}

// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xs() {
}
// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {
}
// Tablets and small desktops /*media screen for maximum width 992px*/
@include screen-md() {
}
// Large tablets and desktops /*media screen for maximum width 1024*/
@include screen-lg() {
}
// Large tablets and desktops /*media screen for maximum width 1400*/
@include screen-xl() {
}
@include screen-xxl() {
}
