// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
$screen-xxs-min: 250px;
// Small tablets and large smartphones (portrait view) /*media screen for maximum width 480*/
$screen-xs-min: 480px;
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
$screen-sm-min: 576px;

// Small tablets (portrait view)/*media screen for maximum width 768*/
$screen-md-min: 768px;

// Tablets and small desktops /*media screen for maximum width 992px*/
$screen-lg-min: 992px;

// Large tablets and desktops /*media screen for maximum width 1024*/
$screen-xl-min: 1024px;
// Large tablets and desktops /*media screen for maximum width 1400*/
$screen-xxl-min: 1400px;


@mixin screen-xxxs() {
    @media only screen and (min-width: 250px) and (max-width: 319px) {
        @content;
    }
}

@mixin screen-xxs() {
    @media only screen and (min-width: #{$screen-xxs-min}) and (max-width: #{$screen-xs-min}) {
        @content;
    }
}

@mixin screen-xs() {
    @media only screen and (min-width: #{$screen-xs-min}) and (max-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin screen-sm() {
    @media only screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin screen-md() {
    @media only screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin screen-lg() {
    @media only screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-xl-min}) {
        @content;
    }
}

@mixin screen-xl() {

    /*media screen for maximum width 1024*/
    @media (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xxl-min}) {
        @content;
    }
}

@mixin screen-xxl() {

    /*media screen for maximum width 1024*/
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
}


// demo code 
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 319*/
@include screen-xxxs() {
 
}


// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
 
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xs() {
   
}
// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {
  
}
// Tablets and small desktops /*media screen for maximum width 992px*/
@include screen-md() {
  
}
// Large tablets and desktops /*media screen for maximum width 1024*/
@include screen-lg() {
   
}
// Large tablets and desktops /*media screen for maximum width 1400*/
@include screen-xl() {

}
@include screen-xxl() {

}