@import "../../../../template/all.scss";

.question_timer_header {
  margin: 15px 0;
  // Small tablets (portrait view)/*media screen for maximum width 768*/
  @include screen-sm() {
    position: relative;
    .countdownTime {
      position: absolute;
      right: 0;
      top: 0px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 500px){
    position: relative;
  .countdownTime{
      position: absolute;
      right: 0;
      top: 0px;
  }
}
}
p.question_count_btn {
  background: $lms_Primary_Blue_secondary;
  display: inline-block;
  padding: 7px 12px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  position: relative;
}
p.question_count_btn::after {
  content: "";
  position: absolute;
  z-index: 99;
  height: 1px;
  width: 1067px;
  background: $lms_Primary_Blue_secondary;
  bottom: 0;
  display: inline-block;
  left: 0;
  @include screen-xxxs() {
    width: 0px;
  }
  @include screen-xxs() {
    width: 0px;
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    width: 0px;
  }
  // Small tablets (portrait view)/*media screen for maximum width 768*/
  @include screen-sm() {
    width: 0px;
  }
}
.form-check-input:checked {
  background-color: $lms_Primary_Blue6;
  border-color: $lms_Primary_Blue6;
}
.q_btn_inner_style {
  margin: 25px 0;
}
button.take-exam.next {
  margin-left: 18px;
}
.take-exam {
  width: 120px;
  display: inline-block;
  padding: 8px 0;
  border-radius: 6px;
  border: none;
  color: white;
  font-size: 14px;
  background-color: $lms_Primary_Blue6;
  border: 1px solid $lms_Primary_Blue6;
  font-weight: 400;
  font-size: 16px;
  @media only screen and (min-width: 250px) and (max-width: 320px) {
    width: 105px;
  }
  &.fade_btn {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
.last-oppr {
  // display: inline-block;
  // padding: 0px 9px 6px;
  // font-size: 14px;
  // color: #ffa100;

  display: inline-block;
  padding: 0px 10px 10px 5px;
  font-size: 14px;
  color: #ffa100;
  margin-left: 32px;
  text-transform: math-auto;
}
// .take-exam:hover {
//     background-color: transparent;
//     border: 1px solid #0A233E;
//     color: #0A233E;
//   }
.modal-footer button.cstm {
  justify-content: flex-start;
  background-color: $lms_Primary_Blue6;
  border: none;
  padding-left: 25px;
  padding-right: 25px;
}
#quizModal .modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
button.close.cstm {
  margin-left: 17px;
}
button.take-exam.card_btn {
  text-align: center;
  margin: 0px auto 15px;
  width: 150px;
  border-radius: 12px;
  padding: 5px 0;
}

button.take-exam.card_btn.disable {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

#idSubmitModal .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  background: whitesmoke;
  border-radius: 15px;
}
.submit-exam-btn.yes {
  width: 160px;
  align-self: center;
  margin: 10px 0px 30px 0px;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
  border: 1px solid $lms_Primary_Blue6;
  color: #fff;
  background-color: $lms_Primary_Blue6;
}

.submit-exam-btn.no {
  width: 160px;
  align-self: center;
  margin: 10px 0px 30px 0px;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
  border: 1px solid #0a233e;
  color: #0a233e;
  background-color: white;
}
.q_sec_main {
  padding: 0 35px;
}

.take-exam.correct_anc_close {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
}
.answers-parent {
  margin-bottom: 40px;
  padding: 0px 8px 0px 8px;
}
// .submit-exam-btn:hover {
//     background-color: #0A233E;
//     border: none;
//     color: white;
//   }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  outline: 0;
  border-radius: 12px;
}
.Quesbody h5 {
  color: #000;
  font-weight: 400;
  font-size: 18px;
}

.notselectedBtn {
  margin: 10px 0;
  // background: $lms_Greyscale_Offgray;
  color: $lms_Greyscale_Black;

  &::before {
    /* >> Symbol you want to use: */
    content: "\f111";
    /* >> Name of the FA free font (mandatory), e.g.:
               - 'Font Awesome 5 Free' for Regular and Solid symbols;
               - 'Font Awesome 5 Pro' for Regular and Solid symbols (Professional License);
               - 'Font Awesome 5 Brand' for Brands symbols. */
    font-family: "Font Awesome 5 Free";
    /* >> Weight of the font (mandatory):
               - 400 for Regular and Brands symbols;
               - 900 for Solid symbols;
               - 300 for Light symbols. */
    font-weight: 900;

    /* >> Optional styling: */
    display: inline-block;
    margin-right: 20px;
    /* ... */
  }
}

.selectedBtn {
  margin: 10px 0;
  background: $lms_Accent_Green;
  color: $lms_Greyscale_White;

  &::before {
    /* >> Symbol you want to use: */
    content: "\f058";
    /* >> Name of the FA free font (mandatory), e.g.:
               - 'Font Awesome 5 Free' for Regular and Solid symbols;
               - 'Font Awesome 5 Pro' for Regular and Solid symbols (Professional License);
               - 'Font Awesome 5 Brand' for Brands symbols. */
    font-family: "Font Awesome 5 Free";
    /* >> Weight of the font (mandatory):
               - 400 for Regular and Brands symbols;
               - 900 for Solid symbols;
               - 300 for Light symbols. */
    font-weight: 900;

    /* >> Optional styling: */
    display: inline-block;
    margin-right: 20px;
    /* ... */
  }
}

.Qlmsbtn {
  width: 120px;
}

.option_style {
  margin-left: 20px;

  button {
    display: block;
    width: 50%;
    text-align: inherit;
    border-bottom: 1px solid;
    border-radius: 0;
    margin: 15px 0;
    font-size: 20px;
    padding-bottom: 15px;
  }
}

.Totaltimeout {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}

.countdownTime {
  // text-align: end;
  // padding-right: 25px;
  // position: absolute;
  // right: 0;
  // display: inline-block;
  .timer_clock {
    align-items: center;
    justify-content: end;
  }

  span {
    margin: 0;
    display: flex;
    align-items: center;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 21px;
    font-weight: 500;
  }
}

.quiz_not_found {
  padding: 10px;
  justify-content: center;
}

.formcheckmcq {
  display: block;
  width: 50%;
  text-align: inherit;
  border-bottom: 1px solid;
  border-radius: 0;
  margin: 20px 0;
  font-size: 20px;
  margin-left: 0;
  padding-left: 0;
  background: $lms_Greyscale_Offgray;
  color: $lms_Greyscale_Black;

  input {
    width: 45%;
    height: 40px;
    position: absolute;
    margin-left: 0 !important;
    visibility: hidden;
  }

  input[type="checkbox"] + .checkbox-label {
    background: $lms_Greyscale_Offgray;
    color: $lms_Greyscale_Black;
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  input[type="checkbox"] + .checkbox-label:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0c8";
    padding-right: 15px;
  }

  input[type="checkbox"]:checked + .checkbox-label {
    background: $lms_Accent_Green;
    padding: 10px;
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: "\f14a";
    padding-right: 15px;
  }

  input[type="checkbox"]:checked + .checkbox-label:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: "\f14a";
    padding-right: 15px;
  }

  // input {
  //     width: 45%;
  //     height: 40px;
  //     position: absolute;

  //     &:checked {
  //         background-color: deeppink;

  //         border: none;
  //         outline: 2px solid deeppink;
  //     }
  // }
}

.fillbtnone {
  background-color: $lms_Greyscale_Gray;
  margin: 20px 40px 5px 0;
  font-size: 20px;
  padding: 15px;
  min-width: 200px;
  cursor: not-allowed;
}

.fillbtn {
  background-color: #2f6da9;
  margin: 20px 40px 5px 40px;
  font-size: 20px;
  padding: 15px;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
  color: #ffffff;
}

.w125 {
  width: 15px;
}
.active_Index {
  border: 3px solid $lms_Accent_Info;
  width: 50px;
  background: darken($color: $lms_Accent_Green, $amount: 1);
  height: 50px;
  border-radius: 25px;
  font-weight: 900;
  margin: 10px;
  padding: 5px;
  color: $lms_Greyscale_White;
}
.inactive_index {
  border: 1px solid #e84c3d;
  width: 50px;
  background: darken(#e84c3d, $amount: 1);
  height: 50px;
  border-radius: 25px;
  font-weight: 900;
  margin: 10px;
  padding: 5px;
  color: #fff;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.selectedAnswer {
  border: 1px solid $lms_Accent_Green;
  width: 50%;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  background: $lms_Accent_Green;
  color: #fff;
  margin-bottom: 20px;
}

.type {
  color: #2f6da9;
}

// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
  .Totaltimeout {
    width: 38px;
    height: 30px;
    margin-top: 7px;
  }
  .inactive_index {
    width: 40px;
    height: 40px;
    margin: 5px;
  }
  .active_Index {
    width: 40px;
    height: 40px;
  }
  .Questionhead.Questionrow h3 {
    font-size: 16px;
    margin: 0;
  }
  .Questionhead {
    display: block;
  }
  .option_style input {
    width: 100% !important;
  }
  .qbtn_sec {
    display: flex;
  }
  // .lmsbtn {
  //     margin: 30px 0px 20px 0px;
  // }
  .option_style button {
    display: inline-flex;
    width: 100%;
    border-bottom: 1px solid;
  }
  .formcheckmcq {
    width: 100%;
  }
  .q_sec_main {
    padding: 0 15px;
  }
  p.question_count_btn::after {
    display: none;
  }
  .countdownTime .timer_clock {
    justify-content: start;
  }
  p.question_count_btn {
    font-size: 15px;
  }
  .Quesbody h5 {
    color: #000;
    font-weight: 400;
    font-size: 16px;
  }
  .take-exam {
    padding: 5px 0;
  }
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xs() {
  .Totaltimeout {
    width: 38px;
    height: 30px;
    margin-top: 7px;
  }
  .countdownTime span {
    font-size: 20px;
  }
  .inactive_index {
    width: 40px;
    height: 40px;
    margin: 5px;
  }
  .active_Index {
    width: 40px;
    height: 40px;
  }
  .Questionhead.Questionrow h3 {
    font-size: 16px;
    margin: 0;
  }
  .Questionhead {
    display: block;
  }
  .option_style input {
    width: 100% !important;
  }
  .qbtn_sec {
    display: flex;
  }
  .option_style button {
    display: inline-flex;
    width: 100%;
    border-bottom: 1px solid;
  }
  .formcheckmcq {
    width: 100%;
  }
  .countdownTime {
    display: inline-block;
  }
}
// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {
  .Totaltimeout {
    width: 38px;
    height: 30px;
    margin-top: 7px;
  }
  .countdownTime span {
    font-size: 20px;
  }
  .inactive_index {
    width: 40px;
    height: 40px;
    margin: 5px;
  }
  .active_Index {
    width: 40px;
    height: 40px;
  }
  .Questionhead.Questionrow h3 {
    font-size: 16px;
    margin: 0;
  }
  .Questionhead {
    display: block;
  }
  .option_style input {
    width: 100% !important;
  }
  .qbtn_sec {
    display: flex;
  }
  .option_style button {
    display: inline-flex;
    width: 100%;
    border-bottom: 1px solid;
  }
  .formcheckmcq {
    width: 100%;
  }
}
