@import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css");
@import "./template/all";
@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

body {
  background: $lms_Greyscale_White;
  font-family: $lms_Regular;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}
.content_view_modal_header.modal-header {
  padding: 10px 25px 10px 15px;
}
.content_view_modal_header .content_modal_title {
  font-size: 17px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.content_body_modal {
  width: 98.9%;
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.page_main {
  background: #d9d9d9;
  padding: 10px;
}
.switch_main .form-switch {
  margin-left: 9px;
}
.switch_main {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 10px 0px 10px 15px;
  position: relative;
  z-index: 9999;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1pxsolidrgba 0, 0, 0, 0.05;
  border-top-left-radius: 10;
  border-radius: 25px;
  border: 1px solid rgb(0, 0, 0) !important;
  width: 95%;
  height: 35px;
}
.toast-header .btn-close {
  margin-right: 0;
  margin-left: 0;
  cursor: pointer;
}
.tostyfy_main .toast-body p {
  margin: 0;
}
// New css for Theme changes start
// ==============================

// common Banner
.cetf_banner img {
  width: 100%;
}

// New css for Theme changes end
// ==============================

.bgimg {
  background-image: url("./template/img/bg.svg");
  background-position: 62% 0%;
  background-size: cover;
  background-repeat: no-repeat;
}
.dropdown-menu.show {
  display: block;
  z-index: 99999;
}

.dashbordcard {
  border: 2px solid $lms_Primary_Blue;
  border-radius: 5px;
  height: 550px;
  overflow-y: auto;

  .card-header {
    border: none;
    color: $lms_Primary_Blue;
    font-family: $lms_Medium;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;

    /* identical to box height, or 165% */
    letter-spacing: 0.02em;
  }
}

.textdanger {
  color: $lms_Primary_Red;
}

.lmscard {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  box-shadow: 4px 4px 12px 1px rgba(0, 0, 0, 0.2);
  line-height: 1.5;
  margin-bottom: 20px;
  margin-top: 20px;
}

.lmscard + .lmscard {
  margin-left: 20px;
}

.lmscard-list {
  display: flex;
  flex: 1;
}

.filetypedragarea {
  border: 3px dashed $lms_Greyscale_Gray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 15px;
  width: 100%;
  height: 200px;
  text-align: center;

  .filetext {
    position: absolute;
    width: calc(100% - 20%);
    height: 200px;

    // // Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
    // @include screen-xxs() {
    //     width: 40%;
    //     height: 50%;

    // }

    // // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
    // @include screen-xs() {
    //     width: 40%;
    //     height: 50%;
    // }

    // // Small tablets (portrait view)/*media screen for maximum width 768*/
    // @include screen-sm() {
    //     width: 40%;
    //     height: 50%;
    // }

    // // Tablets and small desktops /*media screen for maximum width 992px*/
    // @include screen-md() {
    //     width: 80%;
    //     height: 29%;
    // }

    // // Large tablets and desktops /*media screen for maximum width 1024*/
    // @include screen-lg() {
    //     width: 57%;
    //     height: 30%;
    // }

    // // Large tablets and desktops /*media screen for maximum width 1400*/
    // @include screen-xl() {
    //     width: 40%;
    //     height: 50%;
    // }

    // @include screen-xxl() {
    //     width: 40%;
    //     height: 50%;
    // }
  }

  .header {
    font-family: $lms_Bold;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 5px;
    letter-spacing: 0.02em;

    color: $lms_Primary_Blue;
  }

  .subheader {
    font-family: $lms_SemiBold;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 13px;
    /* identical to box height */
    color: $lms_Primary_Blue;
    letter-spacing: 0.02em;
  }

  .subheadermsg {
    font-family: $lms_SemiBold;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: $lms_Primary_Red;
  }
}

.Filestyle {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  opacity: 0;
}

.dragarea.active {
  border: 2px solid $lms_Greyscale_Gray;
}

.errormark {
  color: $lms_Primary_Red;
}

.agtheme {
  height: calc(50vh + 120px);
}

.filetypedragarea {
  border: 3px dashed $lms_Greyscale_Gray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 15px;
  width: 100%;
  height: 200px;
  text-align: center;

  .filetext {
    position: absolute;
    width: calc(100% - 20%);
    height: 200px;

    // // Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
    // @include screen-xxs() {
    //     width: 40%;
    //     height: 50%;

    // }

    // // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
    // @include screen-xs() {
    //     width: 40%;
    //     height: 50%;
    // }

    // // Small tablets (portrait view)/*media screen for maximum width 768*/
    // @include screen-sm() {
    //     width: 40%;
    //     height: 50%;
    // }

    // // Tablets and small desktops /*media screen for maximum width 992px*/
    // @include screen-md() {
    //     width: 80%;
    //     height: 29%;
    // }

    // // Large tablets and desktops /*media screen for maximum width 1024*/
    // @include screen-lg() {
    //     width: 57%;
    //     height: 30%;
    // }

    // // Large tablets and desktops /*media screen for maximum width 1400*/
    // @include screen-xl() {
    //     width: 40%;
    //     height: 50%;
    // }

    // @include screen-xxl() {
    //     width: 40%;
    //     height: 50%;
    // }
  }

  .header {
    font-family: $lms_Bold;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 5px;
    letter-spacing: 0.02em;

    color: $lms_Primary_Blue;
  }

  .subheader {
    font-family: $lms_SemiBold;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 13px;
    /* identical to box height */
    color: $lms_Primary_Blue;
    letter-spacing: 0.02em;
  }

  .subheadermsg {
    font-family: $lms_SemiBold;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: $lms_Primary_Red;
  }
}

.Filestyle {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  opacity: 0;
}

.dragarea.active {
  border: 2px solid $lms_Greyscale_Gray;
}

.navbartext {
  //   background: $lms_Primary_Blue;
  margin-right: -11px;
  // height: 70px;
  .user-name {
    color: $lms_Primary_Blue;
    margin-right: 15px;
  }
  .profile-btn {
    background-color: $lms_Primary_Blue;
    color: #fff;
  }
  .logout-btn {
    color: $lms_Primary_Blue;
    margin-left: 8px;
    margin-right: 10px;
    button {
      background: transparent;
      color: $lms_Primary_Blue;
      border: none;
    }
  }
}
// header-responsive
.header_container_fluid {
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
  @include screen-xxs() {
    flex-wrap: nowrap !important;
    align-items: center;
    .collapse:not(.show) {
      display: block !important;
      flex-basis: auto;
    }
    .navbar-nav {
      display: none;
    }
    .navbar-text.navbartext {
      span {
        display: none;
      }
      .user-name {
        display: none;
      }
      .profile-btn {
        display: none;
      }
    }
    .navbartext {
      background: transparent;
    }
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
  }
  // Small tablets (portrait view)/*media screen for maximum width 768*/
  @include screen-sm() {
  }
}
.agfilterdiv {
  display: flex;
  justify-content: end;

  input {
    margin: 9px 0 0 20px;
    border-radius: 10px;
    padding: 10px;
    width: 64%;
    height: 48px;
  }
}

.agPagediv {
  padding: 10px;
  font-size: 19px;
  margin: auto;

  #page-size {
    margin: 9px 0 0 20px;
    border-radius: 10px;
    padding: 10px;
    width: 75px;
  }
}

.textleft {
  text-align: left;
}

.toast {
  right: 20px;
  bottom: 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding: 0px !important;
}

// .active_submenu {
//   color: $lms_Greyscale_White !important;
//   line-height: 2;
//   letter-spacing: 2px;
//   font-weight: 900;
//   border: 1px solid $lms_Greyscale_White;
//   border-left: 3px solid $lms_Primary_Red;

//   .pro-inner-item {
//     .pro-item-content {
//       a {
//         color: $lms_Greyscale_White;
//       }
//     }
//   }
// }

.active_submenu {
  color: $lms_Primary_Blue6 !important;
  line-height: 2;
  letter-spacing: 2px;
  font-weight: 900;
  // border: 1px solid $lms_Greyscale_White;
  // border-left: 4px solid $lms_Primary_Red;
  background-color: $lms_Primary_Blue6;
  line-height: 3;
  letter-spacing: 2px;
  font-weight: 900;
  height: 60px;
  letter-spacing: inherit;
  border-radius: 12px;
  margin-left: 5px;
  margin-right: 5px;

  span.pro-icon img {
    filter: inherit;
  }

  .pro-inner-item {
    color: white;
    .pro-item-content {
      a {
        color: $lms_Greyscale_White;
      }
    }
  }
}

li.pro-menu-item.active_submenu a {
  color: #fff !important;
}

.inactive_submenu {
  color: $lms_Primary_Blue6;
  .pro-inner-item {
    background-color: white;
    .pro-item-content {
      a {
        color: $lms_Greyscale_White;
      }
    }
  }
}

.sample {
  border-radius: 10px;
  overflow: hidden;
}

.sample-title {
  line-height: 2;
  font-size: 1rem;
  background: $lms_Primary_Blue5;
  color: $lms_Primary_Blue;
  padding: 5px 21px;
  letter-spacing: 1px;
  /* height: 40px; */
  /* display: flex; */
  /* align-content: center; */
  align-items: center;
}

.sample-code {
  border-left: 2px dashed $lms_Primary_Blue5;
  border-right: 2px dashed $lms_Primary_Blue5;
  border-bottom: 2px dashed $lms_Primary_Blue5;
  padding: 20px;
  line-height: 1.5;
}

.sample-html {
  padding: 20px;
  background: #e4f0d0;
}

.sample-html-summary:focus {
  outline: none;
}

.sample-html-summary::-webkit-details-marker {
  display: none;
}

// Responsive meny for mobile device
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
  .sidebar-collapse-btn {
    position: fixed;
    top: 12px;
    z-index: 9999999;
  }
}
.sidebar-collapse-btn {
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    position: fixed;
    top: 12px;
    z-index: 9999999;
  }
  // Small tablets (portrait view)/*media screen for maximum width 768*/
  @include screen-sm() {
    position: fixed;
    top: 12px;
    z-index: 9999999;
  }
}

.sidenavClose .pro-sidebar.collapsed {
  @include screen-xxxs() {
    width: 0px !important;
    min-width: 0px !important;
  }
  @include screen-xxs() {
    width: 0px !important;
    min-width: 0px !important;
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    width: 0px !important;
    min-width: 0px !important;
  }
  // Small tablets (portrait view)/*media screen for maximum width 768*/
  @include screen-sm() {
    width: 0px !important;
    min-width: 0px !important;
  }
}

.sidenavOpen .pro-sidebar {
  @include screen-xxs() {
    width: 270px !important;
    min-width: 270px !important;
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    width: 270px;
    min-width: 270px;
  }
  // Small tablets (portrait view)/*media screen for maximum width 768*/
  @include screen-sm() {
    width: 270px;
    min-width: 270px;
  }
}

// Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
@include screen-xxs() {
  .brand_area {
    width: 150px;
    padding-top: 0;
    margin-left: 30px;
  }
  i.icon_size {
    font-size: 21px;
    color: $lms_Primary_Blue;
  }
  .dashbordcard {
    margin: 25px 0 0 0;
  }
  // Calender

  .css-1vnq923-MuiButtonBase-root-MuiIconButton-root.NavigationButton-button {
    width: 24px !important;
  }
  .css-f7c4o-MuiButtonBase-root-MuiButton-root.TodayButton-button {
    padding: 6.4px 13px !important;
  }
  .css-18bo7dq.Root-root:first-of-type {
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  // Session List
  .post-module .thumbnail img {
    width: 213px;
  }
  // Unit
  .Lessoncard {
    margin-bottom: 5px;
  }
  .DefaultComponent_Headernav__L3Pjn {
    width: 100%;
    position: fixed;
    z-index: 999;
  }
  .app main {
    margin-top: 0px;
  }
  .pro-sidebar {
    margin-top: 67px;
  }
}
// Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
@include screen-xs() {
  .brand_area {
    width: 150px;
    margin-left: 16px;
    padding-top: 0;
  }
  i.icon_size {
    font-size: 21px;
    color: #fff;
  }
  .dashbordcard {
    margin: 25px 0 0 0;
  }
  // Calender
  .css-1vnq923-MuiButtonBase-root-MuiIconButton-root.NavigationButton-button {
    width: 24px !important;
  }
  .css-f7c4o-MuiButtonBase-root-MuiButton-root.TodayButton-button {
    padding: 6.4px 13px !important;
  }
  .css-18bo7dq.Root-root:first-of-type {
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  // Session List
  .post-module .thumbnail img {
    width: 213px;
  }
  // Unit
  .Lessoncard {
    margin-bottom: 5px;
  }
  .DefaultComponent_Headernav__L3Pjn {
    width: 100%;
    position: fixed;
    z-index: 999;
  }
  .app main {
    margin-top: 66px;
  }
  .pro-sidebar {
    margin-top: 67px;
  }
}
// Small tablets (portrait view)/*media screen for maximum width 768*/
@include screen-sm() {
  .brand_area {
    width: 150px;
    margin-left: 12px;
    padding-top: 0;
  }
  i.icon_size {
    font-size: 21px;
    color: #fff;
  }
  .dashbordcard {
    margin: 25px 0 0 0;
  }
  // Calender
  .css-1vnq923-MuiButtonBase-root-MuiIconButton-root.NavigationButton-button {
    width: 24px !important;
  }
  .css-f7c4o-MuiButtonBase-root-MuiButton-root.TodayButton-button {
    padding: 6.4px 13px !important;
  }
  .css-18bo7dq.Root-root:first-of-type {
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  // Session List
  .post-module .thumbnail img {
    width: 213px;
  }

  // Unit
  .Lessoncard {
    margin-bottom: 5px;
  }
  .DefaultComponent_Headernav__L3Pjn {
    width: 100%;
    position: fixed;
    z-index: 999;
  }
  .app main {
    margin-top: 66px;
  }
  .pro-sidebar {
    margin-top: 67px;
  }
}

// header
.brand_area {
  width: 200px;
  .logo-img {
    max-width: 100px;
    max-height: 50px;
  }
}
span.bar {
  margin: 0 25px;
}

.active_menu .open {
  .pro-inner-item {
    .pro-item-content {
      color: $lms_Greyscale_White;
    }
  }

  .react-slidedown.closed {
    display: block;
  }
}

.active_menu {
  .pro-inner-item {
    .pro-item-content {
      color: $lms_Greyscale_White;
    }
  }

  .react-slidedown.closed {
    display: block;
    height: auto !important;
  }
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: $lms_Primary_Blue;
  background-color: transparent;
  border-color: transparent;
}

.h100 {
  height: 100vh;
}

// ag-table
.ag-header-cell-text,
.ag-cell-value {
  text-overflow: clip !important;
  white-space: normal !important;
  word-break: auto-phrase !important;
}

.ag-paging-panel {
  justify-content: space-between !important;

  .ag-paging-row-summary-panel {
    color: $lms_Primary_Blue;
    font-family: $lms_Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    letter-spacing: 0.03em;
  }

  .ag-paging-page-summary-panel {
    color: $lms_Primary_Blue;
    font-family: $lms_Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    letter-spacing: 0.03em;

    .ag-paging-button {
      background-color: $lms_Greyscale_White;

      .ag-icon {
        font-size: 18px !important;
        background: $lms_Greyscale_Offgray;
        padding: 3px;
        border-radius: 4px;
        color: $lms_Primary_Blue;
        box-shadow: 0px 2px 0px #0404044f;
      }
    }
  }
}

.approveActive {
  color: $lms_Accent_Green;
  font-weight: 900;
}

.RejectActive {
  color: $lms_Primary_Red;
  font-weight: 900;
}

.ag-header-row {
  font-family: $lms_SemiBold;
  font-weight: 600;
  font-size: 16px;
  color: $lms_Greyscale_White !important;
  background-color: $lms_Primary_Blue3;
}

.dummy-positioning {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

// The icon specifics
// Variables
$green: #4cc93f;
$icon-base-size: 20px;

// Structure
.success-icon {
  display: inline-block;
  width: 8em;
  height: 8em;
  font-size: $icon-base-size;
  border-radius: 50%;
  border: 4px solid lighten($green, 20%);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(1);
}

// Elements
.success-icon {
  &__tip,
  &__long {
    display: block;
    position: absolute;
    height: 4px;
    background-color: lighten($green, 20%);
    border-radius: 10px;
  }

  &__tip {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    transform: rotate(45deg);
    animation: tipInPlace 300ms ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 180ms;
    visibility: hidden;
  }

  &__long {
    width: 4em;
    transform: rotate(-45deg);
    top: 3.7em;
    left: 2.75em;
    animation: longInPlace 140ms ease-in-out;
    animation-fill-mode: forwards;
    visibility: hidden;
    animation-delay: 300ms + 140ms;
  }
}

@keyframes showSuccess {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.closemenu {
  button {
    color: $lms_Primary_Blue;
  }
}

.home-banner-area {
  background: url(template/img/home-banner-bg.png.webp) no-repeat center;
  background-size: cover;
  height: 100vh;
}

@keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }

  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }

  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible;
  }
}

.cardbody {
  border: 1px solid $lms_Primary_Blue;
  color: $lms_Primary_Blue;
  font-family: $lms_Regular;

  .cardiconbox {
    text-align: center;
    display: flex;
    border: 1px solid #003366;
    /* margin: auto; */
    justify-content: center;
    width: 120px;
    padding: 10px;
    height: 100%;

    img {
      width: 64px;
    }
  }

  .subcardtitle {
    font-family: $lms_Regular;
    color: $lms_Primary_Blue;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
  }

  .cardtitle {
    font-family: $lms_Regular;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: $lms_Primary_Blue;
  }

  // .cardtext {}
}

.Sessionbtn {
  display: flex;
  justify-content: center;

  button {
    margin: 10px;
  }
}

// AppointmentTooltip
.AppointmentTooltip {
  margin-top: 14px;

  .AppointmentTooltiptext {
    a {
      display: inline-block;
      font-family: "Signika-SemiBold";
      color: $lms_Greyscale_White;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      user-select: none;
      background-color: $lms_Primary_Blue;
      width: 200px;
      height: 30px;
      font-weight: 400;
      font-size: 16px;
      margin: 30px 0px 20px 0px;
      padding: 2px;
    }

    p {
      margin: 0;
      color: $lms_Greyscale_Black;
    }

    small {
      color: $lms_Greyscale_Gray;
      display: block;
    }
  }

  .AppointmentTooltipicon {
    font-size: 20px;
    color: $lms_Greyscale_Gray;
  }
}

.Cell-today,
.Cell-text {
  max-width: 100%;
}

.Cell-cell {
  font-family: $lms_SemiBold;
  border: 1px solid $lms_Primary_Blue;
  font-size: 18px;
}

.Cell-dayOfWeek {
  font-family: $lms_SemiBold;
  // border: 1px solid $lms_Primary_Blue;
  font-size: 18px;
}

// .container {
//     //position: relative;
//     // width: 1150px;
//     // padding: 20px;
//     display: flex;
//     justify-content:center;
//     align-items: center;
//     //flex-wrap: wrap;
// }

.container .card {
  //position: relative;
  width: 470px;
  height: 470px;
  background: #fff;
  margin: 20px;
  border-radius: 30px;
  // overflow: hidden;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

// .container:hover .card {
//     filter: blur(20px);
//     transform: scale(0.5);
//     opacity: 0.5;
// }

.container .card:hover {
  filter: blur(0px);
  transform: scale(1.1);
  opacity: 1;
}

.container .card .circle {
  position: absolute;
  font-size: 50px;
  background: $lms_Primary_Blue;
  width: 20%;
  height: 15%;
  text-align: center;
  color: #fff;
  padding: 10px 0;
}

// .container .card .circle h1 {

//     font-size: 2.5em;
//     // padding: 30px 0;
// }

.container .card .content {
  //position: absolute;
  margin-top: 120px;
  padding: 20px;
  text-align: center;
}

.container .card .content p {
  color: #6d082d;
  font-size: 30px;
  font-weight: bold;
}

.button-date {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  //background: rgb(107, 164, 230);
  color: red;
  border-radius: 40px;
  font-weight: bold;
  margin-top: 40px;
  cursor: none;
}

h6 {
  margin-top: 20px;
  font-weight: bolder;
  font-size: 20px;
}

.content span {
  color: blue;
}

.academic {
  margin: 10px;
  color: $lms_Primary_Blue;
}
.heading {
  color: $lms_Primary_Blue;
}
// .container .card:nth-child(1) .circle
// .container .card:nth-child(1) .content button {
//     background: ;
// }

// .container .card:nth-child(2) .circle
// .container .card:nth-child(2) .content button {
//     background: #da2268;
// }

// .container .card:nth-child(3) .circle
// .container .card:nth-child(3) .content button {
//     background: #bb02ff;
// }

// 908507049908507049
.HorizontalAppointment-title {
  white-space: break-spaces !important;
}
.Appointment-appointment {
  height: max-content !important;
}
.datemoment {
  text-transform: capitalize !important;
}
// sidebar
.pro-sidebar .pro-menu a {
  color: #0d4c88 !important;
  font-size: 16px;
  font-weight: 700;
}
.pro-icon img {
  filter: inherit !important;
}
.pro-sidebar .pro-menu .pro-menu-item {
  color: $lms_Primary_Blue;
  background-color: $lms_Primary_Blue6;
  margin: 0px 10px 0px 10px;
}
.pro-menu:hover {
  background-color: white;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: $lms_Primary_Blue !important;
}

.grid-container {
  display: grid;
  // column-gap: 50px;
  grid-template-columns: auto auto auto;

  margin: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  // padding: 20px;
  width: 324px;
  height: 388px;
  font-size: 30px;
  text-align: center;
}

//============== New Loader css ===============//

.loader-container {
  position: fixed;
  height: 100%;
  width: calc(100% - 1px);
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    width: 100%;
  }

  mat-spinner {
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
  }
  .loading-text {
    font-size: 14px;
    text-shadow: 1px 1px 0 #fff;
    color: #7d7d7d;
  }
  .d-flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @keyframes scaling {
    0%,
    100% {
      transform: scale(0.2);
      // background-color: #30FFB7;
      background-color: #217ede;
    }
    40% {
      transform: scale(1);
      // background-color: #07DEFF;
      background-color: #004fb7;
    }
    50% {
      transform: scale(1);
      background-color: #0761ff;
      background-color: #1565c0;
    }
  }

  .div {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    transform: scale(0);
    background-color: red;
    animation: scaling 2.5s ease-in-out infinite;
    display: inline-block;
    margin: 0.5rem;
  }

  .div:nth-child(0) {
    animation-delay: 0s;
  }
  .div:nth-child(1) {
    animation-delay: 0.2s;
  }
  .div:nth-child(2) {
    animation-delay: 0.4s;
  }
  .div:nth-child(3) {
    animation-delay: 0.6s;
  }
  .div:nth-child(4) {
    animation-delay: 0.8s;
  }
  .div:nth-child(5) {
    animation-delay: 1s;
  }
}
.mobile-view-ppt {
  display: none;
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 480*/
  @include screen-xxs() {
    display: block;
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    display: block;
  }
  // Small tablets (portrait view)/*media screen for maximum width 768*/
  @include screen-sm() {
    display: block;
  }
}
.desktop-view-ppt {
  @include screen-xxs() {
    display: none;
  }
  // Small tablets and large smartphones (landscape view) /*media screen for maximum width 576*/
  @include screen-xs() {
    display: none;
  }
  // Small tablets (portrait view)/*media screen for maximum width 768*/
  @include screen-sm() {
    display: none;
  }
}
.pg-viewer-wrapper .document-container {
  padding: 30px;
  width: 100% !important;
  background: #fff;
  margin: 10px 0px !important;
  font-size: 14px !important;
}
.content_frame  {
  height: 700px;
  width: 90%;
  position: relative;
  z-index: 1;
  left: 0px;
  overflow: hidden !important;
}
.pg-viewer-wrapper img {
  max-width: 25% !important;
}
