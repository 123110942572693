@import '../../../template/all.scss';

.Cell-today,
.Cell-text {
    margin-top: 0.33em;
    width: 15.719999999999999em !important;
    /* height: 1.72em; */
    line-height: 1.72;
    /* border-radius: 50%; */
    background: none !important;
    color: $lms_Greyscale_Black !important;
    cursor: default;
    /* margin-right: -9px; */
    /* margin-left: auto; */
    text-align: left !important;
}

.Cell-text {
    color: $lms_Greyscale_Black;
}



.MuiPaper-root {
    font-family: $lms_Regular;
    color: $lms_Greyscale_Black !important;
}

.Cell-otherMonth {
    color: rgba(0, 0, 0, 0.38) !important;
}

.css-1bzx5rb-MuiTableCell-root .Cell-dayOfWeek {
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    /* font-weight: bold; */
    /* font-size: 0.75rem; */
    /* line-height: 1.66; */
    /* letter-spacing: 0.03333em; */
    /* margin: 0; */
    /* padding: 31px; */
    font-family: $lms_Regular !important;
    color: $lms_Greyscale_Black !important;
    padding-bottom: 0;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 69px;
    font-size: 16px;
}

.view{
    margin-top: 80px;
}

.react-datepicker {
    margin-top: 130px;
}
.main-crd .react-datepicker{
    margin-top: 10px !important;
}
.text1 {
    margin-left: 51px;
}