@import 'font';
@import 'color';
@import 'buttons';
@import 'points';

.formgroup {
    label {
        font-family: $lms_Regular;
        font-size: 18px;
        line-height: 22px;
        color: $lms_Primary_Blue;
        font-style: normal;
        margin: 15px 0 20px 0;
    }

    input {

        border: none;
        background: transparent;
        border-bottom: 1.5px solid $lms_Greyscale_Black;
        border-radius: 0;
        margin-bottom: 30px;
        font-family: $lms_Regular;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;


        &::placeholder {
            color: $lms_Greyscale_Gray;
            opacity: .4;
        }
    }

    .Selectontrol {
        border: none;
        background: transparent;
        border-bottom: 1.5px solid $lms_Greyscale_Black;
        border-radius: 0;
        margin-bottom: 15px;
        font-family: $lms_Regular;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        display: block;
        width: 100%;
        height: 35px;

        &::placeholder {
            font-family: $lms_Regular;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            color: $lms_Greyscale_Gray;
        }
    }


}

.drag-area {

    border: 3px dashed $lms_Greyscale_Gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 15px;
    width: 100%;
}


.drag-area .header {
    font-size: 20px;
    font-weight: 500;
    color: #34495e;
}

.drag-area.active {
    border: 2px solid $lms_Greyscale_Gray;
}